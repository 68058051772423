import React, { FC, useState, useEffect } from "react";
import { Container, Content } from "../management/management.page";
import Header from "../../components/Header";
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  Dialog as MuiDialog,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DoneIcon from "@mui/icons-material/Done";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import generator from "generate-password";
import { useAppDispatch } from "../../store";
import { fetchOperators } from "../../features/operator/operatorSlice";
import { labels } from "../../constants/labels";
import Select from "react-select";
import { Role, Tab } from "../../constants/types";
import { setCurrentActiveTab } from "../../features/common/commonSlices";
import { addAdmin, unArchiveEmail } from "../../features/admin/adminSlices";
import Dialog from "../../components/Dialog";

interface Props {}

const Options = [
  { value: Role.Admin, label: labels.admin },
  { value: Role.Salesperson, label: labels.salesperson },
];

const AddAdminUser: FC<Props> = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [shouldPopUpDialog, setShouldPopUpDialog] = useState(false);
  const [currentRoleOption, setCurrentRoleOption] = useState(Options[0]);
  const [isErrorModeOn, setIsErrorModeOn] = useState(false);
  const [shouldPopUpArchivedEmailDialog, setShouldPopUpArchivedEmailDialog] =
    useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onDialogClose = () => {
    setShouldPopUpDialog(false);
    navigate(-1);
    dispatch(fetchOperators());
  };

  const onSelectChange = (selectedOption: any) => {
    setCurrentRoleOption(selectedOption);
  };

  const onGeneratePassword = () => {
    const initialPassword = generator.generate({
      length: 6,
      numbers: false,
      uppercase: true,
      lowercase: false,
    });
    setPassword(initialPassword);
  };

  useEffect(() => {
    dispatch(setCurrentActiveTab(Tab.AdminManagement));
  }, [dispatch]);

  const checkInvalid = () => {
    if (!firstName || !lastName || !email || !password) {
      return true;
    }
    return false;
  };

  const onAcceptUnArchivedEmail = () => {
    setIsLoading(true);
    dispatch(
      unArchiveEmail({
        email: email,
        callback: () => {
          setIsLoading(false);
          setShouldPopUpDialog(true);
        },
      })
    );
  };

  const onAddOperator = () => {
    if (checkInvalid()) {
      setIsErrorModeOn(true);
    } else {
      setIsLoading(true);
      dispatch(
        addAdmin({
          firstName: firstName,
          lastName: lastName,
          email: email.toLowerCase(),
          password: password,
          role: currentRoleOption.value,
          callback: () => {
            setIsLoading(false);
            setShouldPopUpDialog(true);
          },
          errorHandler: (message) => {
            setIsLoading(false);
            setErrorMessage(message);
          },
          archivedEmailErrorHandler: () => {
            setIsLoading(false);
            setShouldPopUpArchivedEmailDialog(true);
          },
        })
      );
    }
  };

  return (
    <Container>
      <MuiDialog
        aria-labelledby="customized-dialog-title"
        open={shouldPopUpDialog}
      >
        <DialogContainer>
          <CheckIconContainer>
            <DoneIcon htmlColor="#9AD87A" fontSize={"large"} />
          </CheckIconContainer>
          <AwesomeText>Awesome!</AwesomeText>
          <DescriptionText>
            The {!shouldPopUpArchivedEmailDialog && "new "}
            {currentRoleOption.value}{" "}
            <span style={{ fontWeight: "700" }}>
              "{`${firstName} ${lastName}`}"
            </span>{" "}
            has been {shouldPopUpArchivedEmailDialog ? "un-archived" : "added"}{" "}
            successfully!
          </DescriptionText>
          <Buttons style={{ marginTop: 40 }}>
            <Button
              onClick={onDialogClose}
              variant="contained"
              color="primary"
              sx={{ textTransform: "none" }}
            >
              {labels.backToAdminUserList}
            </Button>
          </Buttons>
        </DialogContainer>
      </MuiDialog>
      <MuiDialog
        aria-labelledby="customized-dialog-title"
        open={shouldPopUpArchivedEmailDialog}
      >
        <Dialog
          title={labels.emailArchivedTitle}
          content={labels.emailArchivedContent}
          declineText={labels.cancel}
          acceptText={labels.yesUnarchive}
          onDecline={() => setShouldPopUpArchivedEmailDialog(false)}
          onAccept={onAcceptUnArchivedEmail}
        />
      </MuiDialog>
      <Header />
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Content>
        <div>
          <BackButton variant="text" onClick={() => navigate(-1)}>
            <ArrowBackIosIcon fontSize={"small"} color="secondary" />
            <BackText>{labels.backToAdminUserList}</BackText>
          </BackButton>
        </div>
        <AddOperatorText>{labels.addAdminUser}</AddOperatorText>
        <ContentContainer>
          <FullName>
            <InputColumn>
              <TitleText>{labels.firstName}</TitleText>
              <TextField
                error={isErrorModeOn && !firstName}
                helperText={
                  isErrorModeOn && !firstName && "Please enter first name"
                }
                sx={{ marginTop: 1 }}
                color="secondary"
                id="outlined-basic"
                variant="outlined"
                size="small"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder={labels.enterFirstName}
              />
            </InputColumn>
            <InputColumn style={{ marginLeft: 20 }}>
              <TitleText>{labels.lastName}</TitleText>
              <TextField
                error={isErrorModeOn && !lastName}
                helperText={
                  isErrorModeOn && !lastName && "Please enter last name"
                }
                sx={{ marginTop: 1 }}
                color="secondary"
                id="outlined-basic"
                variant="outlined"
                size="small"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder={labels.enterLastName}
              />
            </InputColumn>
          </FullName>
          <FullName style={{ marginTop: 16 }}>
            <InputColumn>
              <TitleText>{labels.email}</TitleText>
              <TextField
                error={isErrorModeOn && !email}
                helperText={isErrorModeOn && !email && "Please enter email"}
                sx={{ marginTop: 1 }}
                color="secondary"
                id="outlined-basic"
                variant="outlined"
                size="small"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={labels.enterEmail}
              />
            </InputColumn>
            <InputColumn style={{ marginLeft: 20 }}>
              <TitleText>{labels.password}</TitleText>
              <TextField
                error={isErrorModeOn && !password}
                helperText={
                  isErrorModeOn && !password && "Please enter password"
                }
                type={showPassword ? "text" : "password"}
                sx={{ marginTop: 1 }}
                color="secondary"
                id="outlined-basic"
                variant="outlined"
                size="small"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </InputColumn>
            <div style={{ marginLeft: 12, marginTop: 27 }}>
              <Button
                onClick={onGeneratePassword}
                variant="outlined"
                sx={{
                  height: 40,
                  width: 120,
                  fontSize: 14,
                  fontWeight: 700,
                  fontFamily: "Mulish",
                  color: "#6E4C8E",
                }}
              >
                Generate
              </Button>
            </div>
          </FullName>
          <TitleText style={{ marginTop: 20 }}>{labels.role}</TitleText>
          <div style={{ width: "30%" }}>
            <Select
              styles={{
                control: (provided) => ({
                  ...provided,
                  height: 40,
                  borderRadius: 4,
                  borderColor: "#D0D5DD",
                  marginTop: 6,
                }),
              }}
              value={currentRoleOption}
              onChange={onSelectChange}
              options={Options}
            />
          </div>
        </ContentContainer>
      </Content>
      <Footer>
        <Buttons>
          <Button
            onClick={() => navigate(-1)}
            variant="outlined"
            sx={cancelButtonStyle}
          >
            Cancel
          </Button>
          <Button
            onClick={onAddOperator}
            variant="contained"
            sx={addButtonStyle}
          >
            Add
          </Button>
        </Buttons>
      </Footer>
    </Container>
  );
};

const cancelButtonStyle = {
  height: 40,
  width: 120,
  fontSize: 14,
  fontWeight: 700,
  fontFamily: "Mulish",
  color: "#6E4C8E",
  textTransform: "none",
};

const addButtonStyle = {
  height: 40,
  width: 120,
  fontSize: 14,
  fontWeight: 700,
  fontFamily: "Mulish",
  marginLeft: 2,
  textTransform: "none",
};

const DescriptionText = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-family: "Mulish";
  margin-top: 12px;
`;

const AwesomeText = styled.div`
  font-size: 20px;
  font-weight: 700;
  font-family: "Mulish";
  color: #2e1a46;
  margin-top: 24px;
`;

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 46px;
  background-color: #ffffff;
  border-radius: 8px;
`;

const CheckIconContainer = styled.div`
  display: flex;
  width: 88px;
  height: 88px;
  border-radius: 50%;
  background-color: #eafbe3;
  align-items: center;
  justify-content: center;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 72px;
  background: #ffffff;
  padding-right: 112px;
`;

export const PasswordGenerateRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 6px;
  width: 100%;
`;

const InputColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
`;

const FullName = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const OperatorRepresentative = styled.div`
  font-family: "Mulish";
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: #2e1a46;
  margin-top: 32px;
`;

const TitleText = styled.label`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #81859a;
`;

const ContentContainer = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 24px 32px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
`;

const BackButton = styled(Button)`
  margin: 0 auto;
  flex-direction: row;
`;

const BackText = styled.label`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #6e4c8e;
  margin-left: 8px;
  text-transform: none;
`;

const AddOperatorText = styled.label`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #2e1a46;
  margin-top: 16px;
  margin-bottom: 24px;
`;

export default AddAdminUser;

import { TabContext, TabPanel } from "@mui/lab";
import {
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from "@mui/material";
import React, { FC, useMemo } from "react";
import { labels } from "../../constants/labels";
import { CustomTab, tableHeaderCellStyles } from "../../constants/styles";
import { FilterStatus, OperatorStatus } from "../../constants/types";
import { tableCellStyles } from "./management.page";
import { formatDate } from "../../constants/utils";
import MoreButton from "../../components/MoreButton";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { setCurrentSuperAdminApprovalTabIndex } from "../../features/common/commonSlices";
import Status from "../../components/Status";
import { Operator } from "../../features/operator/operatorSlice";

interface Props {
  currentOptionIndex: string;
  operators: Operator[];
  setCurrentChoosingUser: (user: any) => void;
  setIsApproveConfirmationModalOpen: (isOpen: boolean) => void;
  setIsRejectConfirmationModalOpen: (isOpen: boolean) => void;
  renderSelectOptions: () => any;
  selectedOption: any;
  setSelectedOption: (optionIndex: number) => void;
  showDisableAlert: (row: any) => void;
  changeToEnableStatus: (row: any) => void;
  onResendInviteButtonPressed: (row: any) => void;
}

const SuperAdminHome: FC<Props> = ({
  currentOptionIndex,
  operators,
  setCurrentChoosingUser,
  setIsApproveConfirmationModalOpen,
  setIsRejectConfirmationModalOpen,
  renderSelectOptions,
  selectedOption,
  setSelectedOption,
  showDisableAlert,
  changeToEnableStatus,
  onResendInviteButtonPressed,
}: Props) => {
  const currentSuperAdminApprovalTabIndex = useSelector(
    (state: RootState) => state.commonSlice.currentSuperAdminApprovalTabIndex
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const onOperatorNameClick = (id: string) => {
    navigate(`/operator-detail/${id}`);
  };

  const filteredOperators = useMemo(() => {
    let resultFilteredOperators = operators;
    switch (currentSuperAdminApprovalTabIndex) {
      case 0:
        resultFilteredOperators = operators.filter(
          (operator) => operator["status"] === OperatorStatus.Approved
        );
        break;
      case 1:
        resultFilteredOperators = operators.filter(
          (operator) => operator["status"] === OperatorStatus.Pending
        );
        break;
      case 2:
        resultFilteredOperators = operators.filter(
          (operator) => operator["status"] === OperatorStatus.Rejected
        );
        break;
      default:
        break;
    }
    if (selectedOption.value === FilterStatus.ENABLED) {
      resultFilteredOperators = resultFilteredOperators.filter(
        (operator: any) =>
          operator["isActive"] && operator["status"] === OperatorStatus.Approved
      );
    } else if (selectedOption.value === FilterStatus.DISABLED) {
      resultFilteredOperators = resultFilteredOperators.filter(
        (operator: any) =>
          !operator["isActive"] ||
          operator["status"] !== OperatorStatus.Approved
      );
    }

    return resultFilteredOperators;
  }, [operators, currentSuperAdminApprovalTabIndex, selectedOption.value]);

  const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedOption(0);
    dispatch(setCurrentSuperAdminApprovalTabIndex(newValue));
  };

  const approveOptions = (user: any) => {
    if (currentSuperAdminApprovalTabIndex === 0) {
      return [
        {
          label: labels.view,
          onClick: () => navigate(`/operator-detail/${user.id}`),
        },
        {
          label: user["isActive"] ? labels.disable : labels.enable,
          onClick: () => {
            user["isActive"]
              ? showDisableAlert(user)
              : changeToEnableStatus(user);
          },
        },
        {
          label: labels.resendInvite,
          onClick: () => onResendInviteButtonPressed(user["id"]),
        },
      ];
    } else if (currentSuperAdminApprovalTabIndex === 2) {
      return [
        {
          label: labels.view,
          onClick: () => navigate(`/operator-detail/${user.id}`),
        },
      ];
    }
    return [
      {
        label: labels.view,
        onClick: () => navigate(`/operator-detail/${user.id}`),
      },
      {
        label: labels.approve,
        onClick: () => {
          setCurrentChoosingUser(user);
          setIsApproveConfirmationModalOpen(true);
          setCurrentChoosingUser(user);
        },
      },
      {
        label: labels.reject,
        onClick: () => {
          setCurrentChoosingUser(user);
          setIsRejectConfirmationModalOpen(true);
          setCurrentChoosingUser(user);
        },
      },
    ];
  };

  return (
    <TabContext value={currentOptionIndex}>
      <Tabs
        value={currentSuperAdminApprovalTabIndex}
        onChange={onTabChange}
        aria-label="basic tabs example"
      >
        <CustomTab label={labels.approved} />
        <CustomTab label={labels.pendingApproval} />
        <CustomTab label={labels.rejected} />
      </Tabs>
      {currentSuperAdminApprovalTabIndex === 0 && renderSelectOptions()}
      <TabPanel value="0" sx={{ paddingLeft: 0, paddingRight: 0 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell sx={tableHeaderCellStyles}>
                  {labels.operatorName}
                </TableCell>
                <TableCell sx={tableHeaderCellStyles}>
                  {labels.operatorRepresentativeName}
                </TableCell>
                <TableCell sx={tableHeaderCellStyles}>
                  {labels.addedBy}
                </TableCell>
                {currentSuperAdminApprovalTabIndex === 0 && (
                  <TableCell sx={tableHeaderCellStyles}>
                    {labels.status}
                  </TableCell>
                )}
                {currentSuperAdminApprovalTabIndex === 0 && (
                  <TableCell sx={tableHeaderCellStyles}>
                    {labels.dateApproved}
                  </TableCell>
                )}
                {currentSuperAdminApprovalTabIndex === 1 && (
                  <TableCell sx={tableHeaderCellStyles}>
                    {labels.dateAdded}
                  </TableCell>
                )}
                {currentSuperAdminApprovalTabIndex === 2 && (
                  <TableCell sx={tableHeaderCellStyles}>
                    {labels.rejectedDate}
                  </TableCell>
                )}
                <TableCell sx={tableHeaderCellStyles}></TableCell>
              </TableRow>
            </TableHead>
            {operators.length === 0 ? null : (
              <TableBody>
                {filteredOperators.map((row, index) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": { backgroundColor: "#F4F4FE;" },
                        backgroundColor: "#fff",
                      }}
                    >
                      <TableCell
                        onClick={() => onOperatorNameClick(row["id"])}
                        sx={[tableCellStyles, { cursor: "pointer" }]}
                      >
                        {row["organization"]}
                      </TableCell>
                      <TableCell sx={tableCellStyles}>{row["name"]}</TableCell>
                      <TableCell sx={tableCellStyles}>
                        {row["addedBy"]}
                      </TableCell>
                      {currentSuperAdminApprovalTabIndex === 0 && (
                        <TableCell sx={tableCellStyles}>
                          <Status
                            isEnable={
                              row["isActive"] &&
                              row["status"] === OperatorStatus.Approved
                            }
                          />
                        </TableCell>
                      )}
                      {currentSuperAdminApprovalTabIndex === 0 && (
                        <TableCell sx={tableCellStyles}>
                          {(row["updateDate"] &&
                            formatDate(new Date(row["updateDate"]))) ||
                            (row["dateAdded"] &&
                              formatDate(new Date(row["dateAdded"])))}
                        </TableCell>
                      )}
                      {currentSuperAdminApprovalTabIndex !== 0 && (
                        <TableCell sx={tableCellStyles}>
                          {row["dateAdded"] &&
                            formatDate(new Date(row["dateAdded"]))}
                        </TableCell>
                      )}
                      <TableCell sx={tableCellStyles}>
                        <MoreButton options={approveOptions(row)} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </TabPanel>
    </TabContext>
  );
};

export default SuperAdminHome;

import { FC, useEffect, useState } from "react";
import {
  Container,
  Content,
  Title,
  TitleRow,
  tableHeaderCellStyles,
} from "../../constants/styles";
import Header from "../../components/Header";
import { labels } from "../../constants/labels";
import FillButton from "../../components/FillButton";
import { useNavigate } from "react-router-dom";
import {
  Backdrop,
  CircularProgress,
  Dialog as MuiDialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { deleteAdmin, fetchAdmins } from "../../features/admin/adminSlices";
import { useAppDispatch } from "../../store";
import { Admin, Role } from "../../constants/types";
import { formatDate } from "../../constants/utils";
import MoreButton from "../../components/MoreButton";
import Dialog from "../../components/Dialog";
import { useAlert } from "react-alert";

interface Props {}

const AdminManagement: FC<Props> = () => {
  const navigate = useNavigate();
  const onAddAdminUserClick = () => navigate("/add-admin-user");
  const dispatch = useAppDispatch();
  const alert = useAlert();
  const [deleteAdminEmail, setDeleteAdminEmail] = useState<string>("");
  const [adminList, setAdminList] = useState<Admin[]>([]);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [currentAdminName, setCurrentAdminName] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchAdminsSuccessCallback = (adminData: Admin[]) => {
    setAdminList(
      adminData.filter(
        (admin) => admin.role !== Role.SuperAdmin && !admin.isArchived
      )
    );
  };

  useEffect(() => {
    if (adminList.length === 0) {
      dispatch(fetchAdmins(fetchAdminsSuccessCallback));
    }
  }, [adminList.length, dispatch]);

  const tableCellStyles = {
    height: 48,
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 16,
  };

  const options = (email: string, name?: string) => [
    {
      label: labels.edit,
      onClick: () => navigate(`/edit-admin/${email}`),
    },
    {
      label: labels.delete,
      onClick: () => onDeleteButtonPressed(email, name),
    },
  ];

  const onDeleteButtonPressed = (email: string, name?: string) => {
    displayConfirmationDialog(email, name);
  };

  const displayConfirmationDialog = (email: string, name?: string) => {
    setIsConfirmationDialogOpen(true);
    setDeleteAdminEmail(email);
    setCurrentAdminName(name || "");
  };

  const acceptDeleteAdmin = () => {
    setIsLoading(true);
    dispatch(
      deleteAdmin({
        email: deleteAdminEmail,
        callback: () => {
          dispatch(fetchAdmins(fetchAdminsSuccessCallback));
          setIsConfirmationDialogOpen(false);
          setDeleteAdminEmail("");
          alert.success(`${currentAdminName} has been deleted successfully`);
          setCurrentAdminName("");
          setIsLoading(false);
        },
      })
    );
    setIsConfirmationDialogOpen(false);
  };

  return (
    <Container>
      <MuiDialog
        aria-labelledby="customized-dialog-title"
        open={isConfirmationDialogOpen}
      >
        <Dialog
          title={labels.confirmation}
          content={labels.deleteAdminConfirmation}
          declineText={labels.cancelDeleteAdmin}
          onDecline={() => {
            setIsConfirmationDialogOpen(false);
            setDeleteAdminEmail("");
          }}
          acceptText={labels.deleteAdmin}
          onAccept={acceptDeleteAdmin}
        />
      </MuiDialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Header />
      <Content>
        <TitleRow>
          <Title>{labels.adminUserList}</Title>
          <FillButton onClick={onAddAdminUserClick}>
            {labels.addAdminUser}
          </FillButton>
        </TitleRow>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell sx={tableHeaderCellStyles}>
                  {labels.fullName.toUpperCase()}
                </TableCell>
                <TableCell sx={tableHeaderCellStyles}>
                  {labels.email.toUpperCase()}
                </TableCell>
                <TableCell sx={tableHeaderCellStyles}>
                  {labels.role.toUpperCase()}
                </TableCell>
                <TableCell sx={tableHeaderCellStyles}>
                  {labels.inviteDate.toUpperCase()}
                </TableCell>
                <TableCell sx={tableHeaderCellStyles}>
                  {labels.userRegistered.toUpperCase()}
                </TableCell>
                <TableCell sx={tableHeaderCellStyles}></TableCell>
              </TableRow>
            </TableHead>
            {adminList.length === 0 ? null : (
              <TableBody>
                {adminList.map((row, index) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": { backgroundColor: "#F4F4FE;" },
                        backgroundColor: "#fff",
                      }}
                    >
                      <TableCell
                        onClick={() => navigate(`/edit-admin/${row["email"]}`)}
                        sx={[tableCellStyles, { cursor: "pointer" }]}
                      >{`${row["firstName"]} ${row["lastName"]}`}</TableCell>
                      <TableCell sx={tableCellStyles}>{row["email"]}</TableCell>
                      <TableCell sx={tableCellStyles}>{row["role"]}</TableCell>
                      <TableCell sx={tableCellStyles}>
                        {formatDate(new Date(row["invitedDate"]))}
                      </TableCell>
                      <TableCell sx={tableCellStyles}>
                        {formatDate(new Date(row["invitedDate"]))}
                      </TableCell>
                      <TableCell sx={tableCellStyles}>
                        <MoreButton
                          isClose={isLoading}
                          options={options(
                            row["email"],
                            `${row["firstName"]} ${row["lastName"]}`
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Content>
    </Container>
  );
};

export default AdminManagement;

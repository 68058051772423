import React, { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Role } from "../../constants/types";
import { FilterStatus } from ".";
import styled from "@emotion/styled";
import OutlineButton from "../../components/OutlineButton";
import { Operator } from "../../features/operator/operatorSlice";
import { labels } from "../../constants/labels";
import { Button } from "@mui/material";

interface Props {
  operator: Operator;
  onApproveButtonPressed: () => void;
  onRejectButtonPressed: () => void;
  onStatusChange: () => void;
}

const ControlRow: FC<Props> = ({
  operator,
  onApproveButtonPressed,
  onRejectButtonPressed,
  onStatusChange,
}: Props) => {
  const user = useSelector((state: RootState) => state.user.user);

  const approveAndRejectButtons = useMemo(
    () =>
      user &&
      user["role"] === Role.SuperAdmin &&
      operator.status === FilterStatus.PendingApproval,
    [operator.status, user]
  );

  const enableDisableButtons = useMemo(
    () =>
      user &&
      user["role"] !== Role.Salesperson &&
      operator.status !== FilterStatus.Rejected,
    [operator.status, user]
  );

  return (
    <>
      {approveAndRejectButtons ? (
        <ApproveRejectRow>
          <OutlineButton onClick={onApproveButtonPressed}>
            {labels.approve}
          </OutlineButton>
          <OutlineButton onClick={onRejectButtonPressed}>
            {labels.reject}
          </OutlineButton>
        </ApproveRejectRow>
      ) : enableDisableButtons ? (
        <DisableButton
          color="secondary"
          variant="outlined"
          onClick={onStatusChange}
        >
          {operator.isActive ? labels.disable : labels.enable}
        </DisableButton>
      ) : (
        <div style={{ height: 40 }}></div>
      )}
    </>
  );
};

const ApproveRejectRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 48px;
  flex-direction: "row";
  align-self: "flex-start";
  gap: 12px;
`;

const DisableButton = styled(Button)`
  width: 120px;
  height: 40px;
  margin-top: 48px;
  text-transform: none;
`;

export default ControlRow;

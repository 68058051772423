import React, { FC } from "react";
import { ReactComponent as KnowbieLogo } from "../../assets/knowbieLogo.svg";

interface Props {}

const Logo: FC<Props> = () => {
  return <KnowbieLogo />;
};

export default Logo;

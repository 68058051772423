import styled from "@emotion/styled";
import { FC } from "react";

interface Props {
  isEnable?: boolean;
  style?: any;
  title?: string;
}

const Status: FC<Props> = ({
  isEnable = false,
  style = {},
  title = "",
}: Props) => {
  return isEnable ? (
    <EnableContainer style={style}>
      <EnableLabel>{title || "Enabled"}</EnableLabel>
    </EnableContainer>
  ) : (
    <DisableContainer style={style}>
      <DisableLabel>{title || "Disabled"}</DisableLabel>
    </DisableContainer>
  );
};

const EnableContainer = styled.div`
  align-items: center;
  justify-content: center;
  height: 24px;
  background-color: #ecfdf3;
  border-radius: 16px;
  border: 1px solid #008a51;
  width: 82px;
  align-items: center;
  justify-content: center;
`;

const EnableLabel = styled.label`
  font-size: 14px;
  font-weight: 500;
  font-family: 'Mulish',
  line-height: 20px;
  color: #008a51;
  text-align: center;
  align-self: center;
  display: block;
`;

const DisableContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  background-color: #eaeaea;
  border-radius: 16px;
  border: 1px solid #535353;
  width: 82px;
`;

const DisableLabel = styled.label`
  font-size: 14px;
  font-weight: 500;
  font-family: 'Mulish',
  line-height: 20px;
  color: #535353;
  text-align: center;
  align-self: center;
  display: block;
`;

export default Status;

import styled from "@emotion/styled";
import { Button } from "@mui/material";
import React, { FC } from "react";

interface Props {
  onClick: () => void;
  children: React.ReactNode;
}

const OutlineButton: FC<Props> = ({ onClick, children }: Props) => {
  return (
    <Container>
      <Button
        onClick={onClick}
        variant="outlined"
        sx={{
          height: 40,
          fontSize: 14,
          fontWeight: 700,
          fontFamily: "Mulish",
          color: "#6E4C8E",
          borderColor: "#6E4C8E",
          boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
          textTransform: "none",
        }}
      >
        {children}
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
`;

export default OutlineButton;

import React, { useState, useEffect } from "react";
const SMALL_WIDTH = 768;

export const formatDate = (date: Date) =>
  date.toLocaleString("default", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

export const isSmallUI = () => {
  const width = window.innerWidth;
  return width <= SMALL_WIDTH;
};

export const useIsSmallUI = (initialValue: boolean) => {
  const [isSmallUI, setIsSmallUI] = useState<boolean>(initialValue);

  useEffect(() => {
    const handleResize = () => setIsSmallUI(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return [isSmallUI, setIsSmallUI];
};

import React, { useState } from "react";

import "./signIn.styles.css";
import knowbieLogo from "../../assets/knowbieLogo.svg";
import { labels } from "../../constants/labels";
import {
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import styled from "@emotion/styled";
import { Credentials, fetchUser } from "../../features/user/userSlices";
import { RootState, useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import Logo from "../../components/Logo";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const SignIn = () => {
  // const { adminCredentials, setAdminCredentials, handleSubmit } =
  //   useContext(AdminContext);
  const [isErrorModeOn, setIsErrorModeOn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  // const { email, password } = adminCredentials;
  const dispatch = useAppDispatch();
  const isLoading = useSelector((state: RootState) => state.user.isLoading);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleChangeEmail = (event: any) => {
    const { value } = event.target;
    setEmail(value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const credential: Credentials = {
      email: email.toLowerCase(),
      password: password,
    };
    dispatch(
      fetchUser({ credential, errorHandler: () => setIsErrorModeOn(true) })
    );
  };

  return (
    <div className="container">
      <div className="content">
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Logo />
          <h3 className="title">{labels.welcome}</h3>
          <div className="inputContainer">
            <label className="label">{labels.email}</label>
          </div>

          <TextField
            error={isErrorModeOn}
            helperText={isErrorModeOn && labels.wrongEmailPassword}
            type={"email"}
            sx={{ marginTop: 1, width: "100%" }}
            color="secondary"
            id="outlined-basic"
            variant="outlined"
            size="small"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email"
          />
          <div className="inputContainer">
            <label className="label">{labels.password}</label>
          </div>

          <TextField
            error={isErrorModeOn}
            helperText={isErrorModeOn && labels.wrongEmailPassword}
            type={showPassword ? "text" : "password"}
            sx={{ marginTop: 1, width: "100%" }}
            color="secondary"
            id="outlined-basic"
            variant="outlined"
            size="small"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <LogInButton
            onClick={handleSubmit}
            color="secondary"
            variant="contained"
          >
            {labels.logIn}
          </LogInButton>
        </>
      </div>
    </div>
  );
};

const LogInButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  margin-top: 40px;
  background-color: #2e1a46;
`;

export default SignIn;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { adminApp } from "../../realm/store";
import { PREFIX } from "../../constants/labels";

const initialState = {};

type NewAdminParams = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  role: string;
  callback?: () => void;
  errorHandler?: (err: any) => void;
  archivedEmailErrorHandler?: () => void;
};

type GetAdminParams = {
  successCallback?: (data: any) => void;
  errorCallback?: (err: any) => void;
  email: string;
};

type UpdateAdminParams = {
  firstName: string;
  lastName: string;
  role: string;
  password: string;
  email: string;
  callback: () => void;
};

type ChangePasswordParams = {
  email: string;
  password: string;
};

type DeleteAdminParams = {
  email: string;
  callback: () => void;
};

type UnArchiveEmailParams = {
  email: string;
  callback: () => void;
};

export const addAdmin = createAsyncThunk(
  "admin/adAdmin",
  async (adminData: NewAdminParams) => {
    try {
      await axios.post(`${PREFIX}/api/admin/add-admin`, {
        firstName: adminData.firstName,
        lastName: adminData.lastName,
        email: adminData.email,
        role: adminData.role,
        password: adminData.password,
        invitedDate: new Date(),
        registeredDate: new Date(),
      });
      await adminApp.emailPasswordAuth.registerUser({
        email: adminData.email,
        password: adminData.password,
      });
      adminData.callback && adminData.callback();
    } catch (err) {
      console.log("err", err);
      const errorData = (err as any)?.response;
      if (errorData?.status === 300) {
        adminData.archivedEmailErrorHandler &&
          adminData.archivedEmailErrorHandler();
      } else {
      }
      adminData.errorHandler &&
        adminData.errorHandler(
          (err as any)?.error ||
            (err as any)?.response?.data.message ||
            "An error occurred"
        );
    }
  }
);

export const unArchiveEmail = createAsyncThunk(
  "admin/unArchiveEmail",
  async ({ email, callback }: UnArchiveEmailParams) => {
    try {
      await axios.post(`${PREFIX}/api/admin/un-archive-email`, {
        email: email,
      });
      callback && callback();
    } catch (err) {
      console.error("Failed to unarchive email", err);
    }
  }
);

export const changePassword = createAsyncThunk(
  "admin/changePassword",
  async (adminData: ChangePasswordParams) => {
    try {
      const { email, password } = adminData;
      await adminApp.emailPasswordAuth.callResetPasswordFunction({
        email: email,
        password: password,
      });
    } catch (err) {
      console.error("Failed to change password", err);
    }
  }
);

export const updateAdmin = createAsyncThunk(
  "admin/updateAdmin",
  async (adminData: UpdateAdminParams) => {
    try {
      await axios.put(`${PREFIX}/api/admin/update-admin`, {
        firstName: adminData.firstName,
        lastName: adminData.lastName,
        role: adminData.role,
        email: adminData.email || "",
        password: adminData.password,
      });
      adminData.callback && adminData.callback();
    } catch (err) {
      console.error("Failed to update admin", err);
    }
  }
);

export const getAdmin = createAsyncThunk(
  "admin/getAdmin",
  async (params: GetAdminParams) => {
    const { email, successCallback, errorCallback } = params;
    try {
      const admin = await axios.get(
        `${PREFIX}/api/admin/get-admin?email=${encodeURIComponent(email)}`
      );
      console.log("aaa", admin);
      successCallback && successCallback(admin.data.data);
    } catch (err) {
      errorCallback && errorCallback(err);
    }
  }
);

export const fetchAdmins = createAsyncThunk(
  "admin/fetchAdmins",
  async (fetchAdminData: (data: any) => void) => {
    try {
      const admins = await axios.get(`${PREFIX}/api/admin/get-admins`);
      fetchAdminData && fetchAdminData(admins.data);
    } catch (err) {
      console.error("Failed to fetch admins", err);
    }
  }
);

export const deleteAdmin = createAsyncThunk(
  "admin/deleteAdmin",
  async (deleteAdminData: DeleteAdminParams) => {
    const { email, callback } = deleteAdminData;
    try {
      await axios.post(`${PREFIX}/api/admin/delete-admin`, {
        email: email,
      });
      callback && callback();
    } catch (err) {
      console.error("Failed to delete admin", err);
    }
  }
);

export const adminSlice = createSlice({
  name: "common",
  initialState,
  reducers: {},
});

// Action creators are generated for each case reducer function

export default adminSlice.reducer;

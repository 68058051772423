import styled from "@emotion/styled";
import React from "react";
import ReasonText from "./reasonText";
import ReasonInput from "./reasonInput";

interface Props {
  rejectReason: string;
  setRejectReason: (reason: string) => void;
}

const Reason: React.FC<Props> = ({ rejectReason, setRejectReason }: Props) => {
  return (
    <Container>
      <ReasonText />
      <ReasonInput
        rejectReason={rejectReason}
        setRejectReason={setRejectReason}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export default Reason;

import React, { FC, memo, useEffect, useMemo } from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { logOut } from "../../features/user/userSlices";
import { labels } from "../../constants/labels";
import { Role, Tab } from "../../constants/types";
import { useNavigate } from "react-router-dom";
import { setCurrentActiveTab } from "../../features/common/commonSlices";
import Logo from "../Logo";
import HamburgerMenu from "../HamburgerMenu";
import UserInfo from "./UserInfo";

interface Props {
  isSmallUI?: boolean | undefined;
}

const Header: FC<Props> = ({ isSmallUI = false }: Props) => {
  const user = useSelector((state: RootState) => state.user.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      window.location.pathname === "/admin-management" &&
      user &&
      user["role"] === Role.SuperAdmin
    ) {
      dispatch(setCurrentActiveTab(Tab.AdminManagement));
    }
  }, [dispatch, user]);
  const currentActiveTab = useSelector(
    (state: RootState) => state.commonSlice.currentActiveTab
  );

  if (!user) {
    return null;
  }

  const onLogOut = () => {
    dispatch(logOut());
    navigate("/");
  };

  const activeStyle = {
    backgroundColor: "#F4F4FE",
    color: "#2E1A46",
  };

  const onAdminManagementClick = () => {
    dispatch(setCurrentActiveTab(Tab.AdminManagement));
    navigate("/admin-management");
  };

  const onOperatorClick = () => {
    dispatch(setCurrentActiveTab(Tab.Operators));
    navigate("/");
  };

  return (
    <Container style={isSmallUI ? { paddingLeft: 16, paddingRight: 16 } : {}}>
      <LeftSide>
        <Logo />
        {!isSmallUI && (
          <>
            <OperatorOption
              style={{ ...(currentActiveTab === Tab.Operators && activeStyle) }}
              onClick={onOperatorClick}
            >
              Operator
            </OperatorOption>
            {user["role"] === Role.SuperAdmin && (
              <OperatorOption
                onClick={onAdminManagementClick}
                style={{
                  ...(currentActiveTab === Tab.AdminManagement && activeStyle),
                  ...{ marginLeft: 16 },
                }}
              >
                {labels.adminManager}
              </OperatorOption>
            )}
          </>
        )}
      </LeftSide>
      <RightSide>
        {isSmallUI ? (
          <HamburgerMenu />
        ) : (
          <>
            <div style={{ marginRight: 33 }}>
              <UserInfo
                email={user["email"]}
                role={user["role"]}
                name={user["name"]}
              />
            </div>
            <LogOut onClick={onLogOut}>Log out</LogOut>
          </>
        )}
      </RightSide>
    </Container>
  );
};

const LogOut = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Mulish',
    line-height: 24px;
    color: #2e1a46;
    padding-left: 32px;
    height: 72px;
    cursor: pointer;
    border-left: 1px solid #E0E0E0;
`;

const RightSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  height: 72px;
  background-color: white;
  padding: 0 112px;
  box-shadow: 0px 1px 7px #ebebeb;
`;

const LeftSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

const OperatorOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: #81859a;
  font-family: "Mulish";
  margin-left: 74px;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
`;

export default memo(Header);

import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import App from "./App";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material/styles";
import { Provider } from "react-redux";
import { store } from "./store";
import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2E1A46",
    },
    secondary: {
      main: "#6E4C8E",
    },
    common: {
      white: "#ffffff",
    },
  },
});

const alertOptions = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 7000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

//@ts-ignore
export const AlertTemplate = ({ options, message, close }) => (
  <AlertContainer
    style={{
      backgroundColor:
        options.type === "info"
          ? "#0D81A4"
          : options.type === "success"
          ? "#008A51"
          : "#E95336",
    }}
  >
    {options.type === "info" && <InfoIcon />}
    {options.type === "success" && <CheckCircleIcon />}
    {options.type === "error" && <ErrorIcon />}
    {message}
    <CloseIcon onClick={close} />
  </AlertContainer>
);

ReactDOM.render(
  <AlertProvider template={AlertTemplate} {...alertOptions}>
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </AlertProvider>,
  document.getElementById("root")
);

const AlertContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 12px;
  gap: 16px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  height: 44px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin-top: 16px;
  z-index: 100;
  pointer-events: auto;
`;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

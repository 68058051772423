import React, { FC, useState } from "react";
import { Container, Content } from "../management/management.page";
import Header from "../../components/Header";
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DoneIcon from "@mui/icons-material/Done";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import generator from "generate-password";
import { addOperator } from "../../features/operator/operatorSlice";
import { RootState, useAppDispatch } from "../../store";
import { fetchOperators } from "../../features/operator/operatorSlice";
import { Role } from "../../constants/types";
import { useSelector } from "react-redux";
import { useIsSmallUI } from "../../constants/utils";
import { setCurrentSuperAdminApprovalTabIndex } from "../../features/common/commonSlices";
import "@fontsource/mulish";

interface Props {}

const AddOperator: FC<Props> = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [operatorName, setOperatorName] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [shouldPopUpDialog, setShouldPopUpDialog] = useState(false);
  const [resultedOperator, setResultedOperator] = useState();
  const [isErrorOn, setIsErrorOn] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useSelector((state: RootState) => state.user.user);
  const [isSmallUI, setIsSmallUI] = useIsSmallUI(window.innerWidth < 768);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onDialogClose = () => {
    setShouldPopUpDialog(false);
    navigate(-1);
    dispatch(fetchOperators());
  };

  const onGeneratePassword = () => {
    const initialPassword = generator.generate({
      length: 6,
      numbers: false,
      uppercase: true,
      lowercase: false,
    });
    setPassword(initialPassword);
  };

  const checkIsInvalid = () =>
    operatorName === "" || fullName === "" || email === "" || password === "";

  const onAddOperator = () => {
    if (checkIsInvalid()) {
      setIsErrorOn(true);
    } else {
      if (user) {
        setIsLoading(true);
        user["role"] === Role.SuperAdmin &&
          dispatch(setCurrentSuperAdminApprovalTabIndex(0));
        dispatch(
          addOperator({
            organization: operatorName,
            name: fullName,
            email: email.toLowerCase().replace(/\s/g, ""),
            password: password,
            role: Role.Operator,
            addedBy: user["name"],
            addedByEmail: user["email"],
            adminId: user["id"],
            adminRole: user["role"],
            callback: (result) => {
              setIsLoading(false);
              setShouldPopUpDialog(true);
              setResultedOperator(result);
            },
            errorHandler: (message) => {
              setIsLoading(false);
              setErrorMessage(message);
            },
          })
        );
      }
    }
  };

  const onViewOperatorDetail = () =>
    resultedOperator && navigate(`/operator-detail/${resultedOperator["_id"]}`);

  return (
    <Container>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={shouldPopUpDialog}
      >
        <DialogContainer>
          <CheckIconContainer>
            <DoneIcon htmlColor="#9AD87A" fontSize={"large"} />
          </CheckIconContainer>
          <AwesomeText>Awesome!</AwesomeText>
          <DescriptionText>
            The new operator{" "}
            <span style={{ fontWeight: "700" }}>"{operatorName}"</span> has been
            added successfully!
          </DescriptionText>
          <Buttons
            style={isSmallUI ? { flexDirection: "column" } : { marginTop: 24 }}
          >
            <Button
              onClick={onDialogClose}
              variant="contained"
              color="primary"
              style={{ textTransform: "none" }}
            >
              Back to Operator List
            </Button>
            <div style={isSmallUI ? { height: 8 } : { width: 12 }} />
            <Button
              style={{ textTransform: "none" }}
              onClick={onViewOperatorDetail}
              variant="contained"
              color="primary"
            >
              View operator details
            </Button>
          </Buttons>
        </DialogContainer>
      </Dialog>
      <Header isSmallUI={typeof isSmallUI === "boolean" ? isSmallUI : false} />
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Content
        style={
          isSmallUI ? { paddingTop: 19, paddingLeft: 24, paddingRight: 24 } : {}
        }
      >
        <div>
          <BackButton variant="text" onClick={() => navigate(-1)}>
            <ArrowBackIosIcon fontSize={"small"} color="secondary" />
            <BackText>Back to Operator List</BackText>
          </BackButton>
        </div>
        <AddOperatorText>Add Operator</AddOperatorText>
        <ContentContainer>
          <TitleText>Operator name</TitleText>
          <TextField
            error={isErrorOn && operatorName.length === 0}
            sx={{ marginTop: 1 }}
            helperText={
              isErrorOn && operatorName.length === 0
                ? "Please enter operator name"
                : ""
            }
            color="secondary"
            id="outlined-basic"
            variant="outlined"
            size="small"
            value={operatorName}
            onChange={(e) => setOperatorName(e.target.value)}
            placeholder="Enter operator name"
          />
          <OperatorRepresentative>
            Operator representative
          </OperatorRepresentative>
          <FullNameEmail style={isSmallUI ? { flexDirection: "column" } : {}}>
            <InputColumn style={isSmallUI ? { width: "100%" } : {}}>
              <TitleText>Full name</TitleText>
              <TextField
                error={isErrorOn && fullName.length === 0}
                sx={{ marginTop: 1 }}
                color="secondary"
                id="outlined-basic"
                variant="outlined"
                size="small"
                value={fullName}
                helperText={
                  isErrorOn && fullName.length === 0
                    ? "Please enter full name"
                    : ""
                }
                onChange={(e) => setFullName(e.target.value)}
                placeholder="Enter full name"
              />
            </InputColumn>
            <InputColumn
              style={isSmallUI ? { width: "100%", marginTop: 20 } : {}}
            >
              <TitleText>Email</TitleText>
              <TextField
                error={isErrorOn && email.length === 0}
                sx={{ marginTop: 1 }}
                color="secondary"
                id="outlined-basic"
                variant="outlined"
                size="small"
                helperText={
                  isErrorOn && email.length === 0 ? "Please enter email" : ""
                }
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email"
              />
            </InputColumn>
          </FullNameEmail>
          <TitleText style={{ marginTop: 16 }}>Password</TitleText>
          <PasswordGenerateRow>
            <TextField
              error={isErrorOn && password.length === 0}
              type={showPassword ? "text" : "password"}
              sx={isSmallUI ? {} : { width: "49%" }}
              color="secondary"
              id="outlined-basic"
              variant="outlined"
              size="small"
              helperText={
                isErrorOn && password.length === 0
                  ? "Please enter password"
                  : ""
              }
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div style={isSmallUI ? {} : { width: "49%" }}>
              <Button
                onClick={onGeneratePassword}
                variant="outlined"
                sx={generateButtonStyle}
              >
                Generate
              </Button>
            </div>
          </PasswordGenerateRow>
        </ContentContainer>
      </Content>
      <Footer style={isSmallUI ? { paddingRight: 16 } : {}}>
        <Buttons>
          <Button
            onClick={() => navigate(-1)}
            variant="outlined"
            sx={{
              height: 40,
              width: 120,
              fontSize: 14,
              fontWeight: 700,
              fontFamily: "Mulish",
              color: "#6E4C8E",
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={onAddOperator}
            variant="contained"
            sx={{
              height: 40,
              width: 120,
              fontSize: 14,
              fontWeight: 700,
              fontFamily: "Mulish",
              marginLeft: 2,
              textTransform: "none",
            }}
          >
            Add
          </Button>
        </Buttons>
      </Footer>
    </Container>
  );
};

const generateButtonStyle = {
  height: 40,
  width: 120,
  fontSize: 14,
  fontWeight: 700,
  fontFamily: "Mulish",
  color: "#6E4C8E",
  textTransform: "none",
};

const DescriptionText = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-family: "Mulish";
  margin-top: 12px;
  text-align: center;
`;

const AwesomeText = styled.div`
  font-size: 20px;
  font-weight: 700;
  font-family: "Mulish";
  color: #2e1a46;
  margin-top: 24px;
`;

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 46px;
  background-color: #ffffff;
  border-radius: 8px;
`;

const CheckIconContainer = styled.div`
  display: flex;
  width: 88px;
  height: 88px;
  border-radius: 50%;
  background-color: #eafbe3;
  align-items: center;
  justify-content: center;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 72px;
  background: #ffffff;
  padding-right: 112px;
`;

export const PasswordGenerateRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 6px;
  width: 100%;
`;

const InputColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;
`;

const FullNameEmail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 28px;
  width: 100%;
`;

export const OperatorRepresentative = styled.div`
  font-family: "Mulish";
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: #2e1a46;
  margin-top: 32px;
`;

const TitleText = styled.label`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #81859a;
`;

const ContentContainer = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 24px 32px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
`;

const BackButton = styled(Button)`
  flex-direction: row;
`;

const BackText = styled.label`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #6e4c8e;
  margin-left: 8px;
  text-transform: none;
`;

const AddOperatorText = styled.label`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #2e1a46;
  margin-top: 16px;
  margin-bottom: 24px;
`;

export default AddOperator;

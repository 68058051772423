import styled from "@emotion/styled";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

type Props = {
  message: string;
};

const SuccessAlert = ({ message = "Success!" }: Props) => {
  return (
    <Container>
      <CheckCircleIcon sx={{ color: "common.white" }} />
      <Label>{message}</Label>
    </Container>
  );
};

const Label = styled.div`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
`;

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 16px;
  width: 100%;
  height: 44px;
  background: #008a51;
  border: 1px solid #008a51;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 1;
`;

export default SuccessAlert;

import React, { FC } from "react";
import { TitleText } from "../../constants/styles";
import { TextField } from "@mui/material";
import styled from "@emotion/styled";

interface Props {
  title: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  disabled?: boolean;
}

const InputInfo: FC<Props> = ({
  title = "",
  value,
  onChange,
  placeholder,
  disabled = false,
}: Props) => {
  return (
    <Container>
      <TitleText>{title}</TitleText>
      <TextField
        disabled={disabled}
        sx={{ marginTop: 1 }}
        color="secondary"
        id="outlined-basic"
        variant="outlined"
        size="small"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export default InputInfo;

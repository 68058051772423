import { Button } from "@mui/material";
import React, { FC } from "react";
import styled from "@emotion/styled";

interface Props {
  onClick: () => void;
  children: React.ReactNode;
  isDisabled?: boolean;
}

const FillButton: FC<Props> = ({
  onClick,
  children,
  isDisabled = false,
}: Props) => {
  return (
    <Container>
      <Button
        disabled={isDisabled}
        variant="contained"
        onClick={onClick}
        sx={{
          backgroundColor: isDisabled ? "#DCCDE3" : "#2E1A46",
          borderColor: isDisabled ? "#DCCDE3" : "#2E1A46",
          height: 40,
          textTransform: "none",
        }}
      >
        {children}
      </Button>
    </Container>
  );
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: 'Mulish',
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-transform: capitalize;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    height: 40px;
    gap: 8px;
    cursor: pointer;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
  `;

export default FillButton;

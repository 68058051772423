import React, { FC, useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
import styled from "@emotion/styled";
import generator from "generate-password";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  BackButton,
  BackText,
  CommonButton,
  Container,
  Content,
  CustomTab,
  FullNameEmail,
  InputColumn,
  TextButton,
  Title,
  TitleText,
} from "../../constants/styles";
import { labels } from "../../constants/labels";
import {
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog as MuiDialog,
  TextField,
} from "@mui/material";
import { RootState, useAppDispatch } from "../../store";
import {
  Operator,
  changeOperatorApprovalStatus,
  editOperatorStatus,
  fetchOperator,
  fetchOperators,
  getServers,
  resendInvitation,
  resendServerInvitation,
  updateOperator,
  updateServer,
} from "../../features/operator/operatorSlice";
import ShortInfo from "../../components/ShortInfo";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { default as StatusOperator } from "./Status";
import Status from "../../components/Status";
import { OperatorRepresentative, PasswordGenerateRow } from "../addOperator";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import OutlineButton from "../../components/OutlineButton";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { OperatorStatus, Role } from "../../constants/types";
import Dialog from "../../components/Dialog";
import MoreButton from "../../components/MoreButton";
import InputInfo from "../../components/InputInfo";
import FillButton from "../../components/FillButton";
import SuccessAlert from "../../components/SuccessAlert";
import { setTimeout } from "timers";
import { useIsSmallUI } from "../../constants/utils";
import ControlRow from "./controlRow";
import RejectedReason from "./rejectedReason";
import Reason from "../management/reason";

interface Props {}

export enum FilterStatus {
  Total = "total",
  Completed = "completed",
  NotStarted = "notStarted",
  InProgress = "inProgress",
  NotJoined = "notJoined",
  PendingApproval = "Pending",
  Rejected = "Rejected",
}

const OperatorDetail: FC<Props> = () => {
  const navigate = useNavigate();
  const [operator, setOperator] = useState<Operator>();
  const [serverListLoaded, setServerListLoaded] = useState<boolean>(false);
  const [serverList, setServerList] = useState([]);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [currentFilter, setCurrentFilter] = useState<string>(
    FilterStatus.Total
  );
  const dispatch = useAppDispatch();
  const [operatorName, setOperatorName] = useState<string>("");
  const { operatorId } = useParams();
  const [fullName, setFullName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [showServerPassword, setShowServerPassword] = useState<boolean>(false);
  const [serverPassword, setServerPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const alert = useAlert();
  const user = useSelector((state: RootState) => state.user.user);
  const [confirmationPopup, setConfirmationPopup] = useState<boolean>(false);
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
  const [serverFirstName, setServerFirstName] = useState<string>("");
  const [serverLastName, setServerLastName] = useState<string>("");
  const [serverEmail, setServerEmail] = useState<string>("");
  const [currentChoosingServer, setCurrentChoosingServer] = useState<any>();
  const [isSmallUI, setIsSmallUI] = useIsSmallUI(window.innerWidth < 768);
  const [shouldDisplaySuccessAlert, setShouldDisplaySuccessAlert] =
    useState<boolean>(false);
  const [isRejectPopupOpen, setIsRejectPopupOpen] = useState<boolean>(false);
  const [rejectReason, setRejectReason] = useState<string>("");

  useEffect(() => {
    if (!operatorId || operator) return;
    dispatch(
      fetchOperator({
        operatorId: operatorId as string,
        successCallback: (data) => {
          setOperator(data);
          setOperatorName(data.organization);
          setFullName(data.name);
          setEmail(data.email || "");
          setPassword(data.password || "");
        },
        errorCallback: (error) => console.log("error", error),
      })
    );
  }, [dispatch, operator, operatorId]);

  useEffect(() => {
    !serverListLoaded &&
      dispatch(
        getServers({
          operatorId: operatorId as string,
          successCallback: (data) => {
            setServerList(data);
            setServerListLoaded(true);
          },
        })
      );
  }, [dispatch, operator, operatorId, serverList, serverListLoaded]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentFilter(newValue as FilterStatus);
  };

  const isChanged = useMemo(() => {
    if (!operator) return false;
    return (
      operator.organization !== operatorName ||
      operator.name !== fullName ||
      operator.email !== email ||
      operator.password !== password
    );
  }, [email, fullName, operator, operatorName, password]);

  const isEmpty = useMemo(() => {
    return !operatorName || !fullName || !email || !password;
  }, [email, fullName, operatorName, password]);

  const shouldDisable = useMemo(() => {
    return isEmpty || !isChanged;
  }, [isEmpty, isChanged]);

  const onStatusChange = () => {
    operator &&
      dispatch(
        editOperatorStatus({
          operatorId: operatorId || "",
          status: !operator.isActive,
          onSuccess: () =>
            dispatch(
              fetchOperator({
                operatorId: operatorId as string,
                successCallback: (data) => setOperator(data),
              })
            ),
        })
      );
  };

  const onBackToOperatorList = () => {
    dispatch(fetchOperators());
    navigate("/");
  };

  const onGeneratePassword = () => {
    const initialPassword = generator.generate({
      length: 6,
      numbers: false,
      uppercase: true,
      lowercase: false,
    });
    setPassword(initialPassword);
  };

  const onEditButtonPressed = () => {
    setIsEditMode(!isEditMode);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickShowServerPassword = () =>
    setShowServerPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onUpdateAndResendButtonPressed = () => {
    onUpdateButtonPressed(true);
  };

  const onUpdateButtonPressed = (shouldResendInvitation: boolean = false) => {
    if (operator) {
      setIsLoading(true);
      dispatch(
        updateOperator({
          operatorData: {
            name: fullName,
            email: email,
            password: password,
            organization: operatorName,
          },
          isUpdatingEmail: operator.email !== email,
          isUpdatingPassword: operator.password !== password,
          operatorId: operatorId || "",
          successCallback: () =>
            dispatch(
              fetchOperator({
                operatorId: operatorId as string,
                successCallback: (data) => {
                  setIsLoading(false);
                  setOperator(data);
                  setOperatorName(data.organization);
                  setFullName(data.name);
                  setEmail(data.email || "");
                  setPassword(data.password || "");
                  alert.show("Operator information has been updated", {
                    type: "success",
                  });
                  if (shouldResendInvitation) {
                    alert.show("Resending invite", {
                      type: "info",
                    });
                    dispatch(
                      resendInvitation({
                        operatorId: operatorId || "",
                        successCallback: () =>
                          alert.show("An invite has been resent successfully", {
                            type: "success",
                          }),
                        errorCallback: () =>
                          alert.show("Resending Invitation failed", {
                            type: "error",
                          }),
                      })
                    );
                  }
                },
                errorCallback: (error) => console.log("error", error),
              })
            ),
        })
      );
    }
  };

  const courseCompleted = useMemo(() => {
    return serverList.filter(
      (server) =>
        server["currentModuleIndex"] > 0 ||
        server["currentChapterIndex"] > 23 ||
        (server["moduleProgressDetails"] != null &&
          server["moduleProgressDetails"][0] &&
          server["moduleProgressDetails"][0]["currentChapterIndex"] > 23)
    );
  }, [serverList]);

  const inProgress = useMemo(() => {
    return serverList.filter(
      (server) =>
        server["status"] !== "Invited" &&
        ((server["currentModuleIndex"] < 1 &&
          server["currentChapterIndex"] <= 23 &&
          server["currentChapterIndex"] > 0 &&
          (!server["moduleProgressDetails"] ||
            server["moduleProgressDetails"][0] === undefined)) ||
          (server["moduleProgressDetails"] &&
            server["moduleProgressDetails"][0] &&
            (server["moduleProgressDetails"][0]["currentChapterIndex"] > 0 ||
              (server["moduleProgressDetails"][0]["currentChapterIndex"] ===
                0 &&
                server["moduleProgressDetails"][0]["currentPageIndex"] > 0)) &&
            server["moduleProgressDetails"][0]["currentChapterIndex"] <= 23))
    );
  }, [serverList]);

  const notStarted = useMemo(() => {
    return serverList.filter(
      (server) =>
        server["status"] !== "Invited" &&
        ((server["currentModuleIndex"] < 1 &&
          server["currentChapterIndex"] < 1 &&
          (!server["moduleProgressDetails"] ||
            server["moduleProgressDetails"][0] === undefined)) ||
          (server["moduleProgressDetails"] &&
            server["moduleProgressDetails"][0] &&
            server["moduleProgressDetails"][0]["currentChapterIndex"] === 0 &&
            server["moduleProgressDetails"][0]["currentPageIndex"] === 0))
    );
  }, [serverList]);

  const notJoined = useMemo(() => {
    return serverList.filter((server) => server["status"] === "Invited");
  }, [serverList]);

  const getStatusType = (row: any) => {
    if (row["status"] === "Invited") {
      return FilterStatus.NotJoined;
    } else {
      if (
        row["currentModuleIndex"] > 0 ||
        row["currentChapterIndex"] > 23 ||
        (row["moduleProgressDetails"] &&
          row["moduleProgressDetails"].length > 0 &&
          row["moduleProgressDetails"][0] &&
          row["moduleProgressDetails"][0]["currentChapterIndex"] > 23)
      ) {
        return FilterStatus.Completed;
      } else if (
        (row["currentModuleIndex"] < 1 &&
          row["currentChapterIndex"] <= 23 &&
          row["currentChapterIndex"] > 0) ||
        (row["moduleProgressDetails"] &&
          row["moduleProgressDetails"].length > 0 &&
          row["moduleProgressDetails"][0] &&
          (row["moduleProgressDetails"][0]["currentChapterIndex"] > 0 ||
            (row["moduleProgressDetails"][0]["currentChapterIndex"] === 0 &&
              row["moduleProgressDetails"][0]["currentPageIndex"] > 0)) &&
          row["moduleProgressDetails"][0]["currentChapterIndex"] <= 23)
      ) {
        return FilterStatus.InProgress;
      } else if (
        (row["currentModuleIndex"] < 1 && row["currentChapterIndex"] < 1) ||
        (row["moduleProgressDetails"] &&
          row["moduleProgressDetails"].length > 0 &&
          row["moduleProgressDetails"][0] &&
          row["moduleProgressDetails"][0]["currentChapterIndex"] < 1)
      ) {
        return FilterStatus.NotStarted;
      } else if (
        row["currentModuleIndex"] < 1 &&
        row["currentChapterIndex"] === null
      ) {
        return FilterStatus.NotJoined;
      }
    }
  };

  const onCancelEdit = () => {
    setIsEditMode(false);
    setFullName(operator?.name || "");
    setEmail(operator?.email || "");
    setPassword(operator?.password || "");
  };

  const onApproveButtonPressed = () => setConfirmationPopup(true);

  const onConfirmApprove = () => {
    setConfirmationPopup(false);
    dispatch(
      changeOperatorApprovalStatus({
        email: operator?.email || "",
        status: OperatorStatus.Approved,
        successCallback: () =>
          dispatch(
            fetchOperator({
              operatorId: operatorId as string,
              successCallback: (data) => {
                setOperator(data);
                alert.show("Operator has been approved", {
                  type: "success",
                });
              },
              errorCallback: (error) => console.log("error", error),
            })
          ),
      })
    );
  };

  const onRejectOperator = () => {
    setIsLoading(true);
    setIsRejectPopupOpen(false);
    dispatch(
      changeOperatorApprovalStatus({
        email: operator?.email || "",
        rejectReason: rejectReason,
        status: OperatorStatus.Rejected,
        successCallback: () => {
          dispatch(
            fetchOperator({
              operatorId: operatorId as string,
              successCallback: (data) => {
                setOperator(data);
                alert.show("Operator has been rejected", {
                  type: "success",
                });
                setRejectReason("");
                setIsLoading(false);
              },
              errorCallback: (error) => console.log("error", error),
            })
          );
        },
      })
    );
  };

  const onRejectButtonPressed = () => setIsRejectPopupOpen(true);

  const onCloseButtonPressed = () => setEditDialogOpen(false);

  const onResendInviteServerSuccess = () => {
    setShouldDisplaySuccessAlert(true);
    setTimeout(() => {
      setShouldDisplaySuccessAlert(false);
    }, 7000);
  };

  const onResendInviteServer = () => {
    dispatch(
      resendServerInvitation({
        serverId: currentChoosingServer?._id || "",
        successCallback: () => onResendInviteServerSuccess(),
        errorCallback: () =>
          alert.show("Resending Invitation failed", {
            type: "error",
          }),
      })
    );
  };

  const onCancelConfirmation = () => {
    setConfirmationPopup(false);
    setIsRejectPopupOpen(false);
  };

  const onUpdateServer = () => {
    dispatch(
      updateServer({
        serverId: currentChoosingServer?._id || "",
        serverData: {
          firstName: serverFirstName,
          lastName: serverLastName,
          email: serverEmail,
        },
        successCallback: () => {
          alert.show(labels.serverInfoUpdated, {
            type: "success",
          });
          setEditDialogOpen(false);
          dispatch(
            getServers({
              operatorId: operatorId as string,
              successCallback: (data) => {
                setServerList(data);
              },
            })
          );
        },
      })
    );
  };

  const serverOptions = (server: any) => {
    return [
      {
        label: labels.edit,
        onClick: () => {
          setEditDialogOpen(true);
          setCurrentChoosingServer(server);
          server && setServerFirstName(server["first_name"] || "");
          server && setServerLastName(server["last_name"] || "");
          server && setServerEmail(server["email"] || "");
          server && setServerPassword(server["initialPassword"] || "");
        },
      },
      {
        label: labels.resendInvite,
        onClick: () =>
          dispatch(
            resendServerInvitation({
              serverId: server?._id || "",
              successCallback: () =>
                alert.success(
                  `${labels.successResendInviteServer} ${server["first_name"]} ${server["last_name"]}`
                ),
              errorCallback: () =>
                alert.show("Resending Invitation failed", {
                  type: "error",
                }),
            })
          ),
      },
    ];
  };

  const CustomTabPanel = ({ value, list }: { value: string; list: any }) => {
    return (
      <TabPanel value={value} sx={{ padding: 0 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <CustomTableRow>
                <TableCell sx={TableCellCustomStyle}>
                  {labels.fullName}
                </TableCell>
                <TableCell sx={TableCellCustomStyle}>{labels.email}</TableCell>
                <TableCell sx={TableCellCustomStyle}>{labels.status}</TableCell>
                <TableCell sx={TableCellCustomStyle}>
                  {labels.inviteDate}
                </TableCell>
                <TableCell sx={TableCellCustomStyle}>
                  {labels.userRegistered}
                </TableCell>
                <TableCell sx={TableCellCustomStyle}></TableCell>
              </CustomTableRow>
            </TableHead>
            <TableBody>
              {list.map(
                (
                  row: { [x: string]: any },
                  index: React.Key | null | undefined
                ) => (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor: "#fff",
                    }}
                  >
                    <TableCell
                      sx={cellStyle}
                    >{`${row["first_name"]} ${row["last_name"]}`}</TableCell>
                    <TableCell sx={cellStyle}>{row["email"]}</TableCell>
                    <TableCell>
                      <StatusOperator
                        type={getStatusType(row) || FilterStatus.Completed}
                      />
                    </TableCell>
                    <TableCell sx={cellStyle}>
                      {new Date(row["invitedDate"]).toLocaleString("default", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      })}
                    </TableCell>
                    <TableCell sx={cellStyle}>
                      {row["userRegistered"]
                        ? new Date(row["userRegistered"]).toLocaleString(
                            "default",
                            {
                              month: "short",
                              day: "numeric",
                              year: "numeric",
                            }
                          )
                        : "-"}
                    </TableCell>
                    <TableCell sx={cellStyle}>
                      <MoreButton options={serverOptions(row)} />
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
    );
  };

  return (
    <Container>
      <MuiDialog
        open={confirmationPopup || isRejectPopupOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Dialog
          title={labels.confirmation}
          content={
            confirmationPopup
              ? labels.approveAdminConfirmation
              : labels.rejectAdminConfirmation
          }
          declineText={labels.cancelDeleteAdmin}
          onDecline={onCancelConfirmation}
          acceptText={confirmationPopup ? labels.yesApprove : labels.yesReject}
          onAccept={confirmationPopup ? onConfirmApprove : onRejectOperator}
        >
          {isRejectPopupOpen && (
            <Reason
              rejectReason={rejectReason}
              setRejectReason={setRejectReason}
            />
          )}
        </Dialog>
      </MuiDialog>
      <MuiDialog open={editDialogOpen}>
        <EditServerContainer>
          <DialogTitle>{labels.editServer}</DialogTitle>
          {shouldDisplaySuccessAlert && (
            <SuccessAlert
              message={`${labels.successResendInviteServer} ${currentChoosingServer["first_name"]} ${currentChoosingServer["last_name"]}`}
            />
          )}
          <CustomInputInfo>
            <InputInfo
              title={labels.firstName}
              value={serverFirstName}
              onChange={(e) => setServerFirstName(e.target.value)}
              placeholder={labels.enterFirstName}
            />
          </CustomInputInfo>
          <CustomInputInfo>
            <InputInfo
              title={labels.lastName}
              value={serverLastName}
              onChange={(e) => setServerLastName(e.target.value)}
              placeholder={labels.enterLastName}
            />
          </CustomInputInfo>
          <CustomInputInfo>
            <InputInfo
              disabled={true}
              title={labels.email}
              value={serverEmail}
              onChange={(e) => setServerEmail(e.target.value)}
              placeholder={labels.enterEmail}
            />
          </CustomInputInfo>
          <TitleText style={{ marginTop: 16 }}>{labels.password}</TitleText>
          <TextField
            disabled={true}
            type={showServerPassword ? "text" : "password"}
            sx={{ marginTop: 1 }}
            color="secondary"
            id="outlined-basic"
            variant="outlined"
            size="small"
            value={serverPassword}
            onChange={(e) => setServerPassword(e.target.value)}
            placeholder="Enter password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowServerPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showServerPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <ServerDialogControlRow>
            <OutlineButton onClick={onCloseButtonPressed}>
              {labels.close}
            </OutlineButton>
            <div style={{ marginLeft: 12 }}>
              <FillButton onClick={onResendInviteServer}>
                {labels.resendInvite}
              </FillButton>
            </div>
            <div style={{ marginLeft: 12 }}>
              <FillButton
                isDisabled={
                  !currentChoosingServer ||
                  (serverFirstName === currentChoosingServer["first_name"] &&
                    serverLastName === currentChoosingServer["last_name"] &&
                    serverEmail === currentChoosingServer["email"]) ||
                  !serverFirstName ||
                  !serverLastName ||
                  !serverEmail ||
                  !serverPassword
                }
                onClick={onUpdateServer}
              >
                {labels.update}
              </FillButton>
            </div>
          </ServerDialogControlRow>
        </EditServerContainer>
      </MuiDialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Header isSmallUI={typeof isSmallUI === "boolean" ? isSmallUI : false} />
      <Content
        style={
          isSmallUI ? { paddingTop: 14, paddingLeft: 16, paddingRight: 16 } : {}
        }
      >
        <div>
          <BackButton variant="text" onClick={onBackToOperatorList}>
            <ArrowBackIosIcon fontSize={"small"} color="secondary" />
            <BackText>{labels.backToOperatorList}</BackText>
          </BackButton>
        </div>
        <Title>{labels.operatorDetail}</Title>
        {!operator ? (
          <LinearProgress sx={{ marginTop: 3 }} color="secondary" />
        ) : (
          <>
            <Headline>{labels.operatorInformation}</Headline>
            {isEditMode ? (
              <InformationContainer>
                <TitleText>{labels.operatorName}</TitleText>
                <TextField
                  sx={{ marginTop: 1, maxWidth: 768 }}
                  color="secondary"
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  value={operatorName}
                  onChange={(e) => setOperatorName(e.target.value)}
                  placeholder="Enter operator name"
                />
                <OperatorRepresentative>
                  {labels.operatorRepresentative}
                </OperatorRepresentative>
                <FullNameEmail>
                  <InputColumn>
                    <TitleText>{labels.fullName}</TitleText>
                    <TextField
                      sx={{ marginTop: 1 }}
                      color="secondary"
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      placeholder="Enter full name"
                    />
                  </InputColumn>
                  <InputColumn>
                    <TitleText>{labels.email}</TitleText>
                    <TextField
                      sx={{ marginTop: 1 }}
                      color="secondary"
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter email"
                    />
                  </InputColumn>
                </FullNameEmail>
                <TitleText style={{ marginTop: 16 }}>Password</TitleText>
                <PasswordGenerateRow>
                  <TextField
                    type={showPassword ? "text" : "password"}
                    sx={{ width: "49%" }}
                    color="secondary"
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div style={{ width: "49%" }}>
                    <Button
                      onClick={onGeneratePassword}
                      variant="outlined"
                      sx={generateButtonStyle}
                    >
                      {labels.generate}
                    </Button>
                  </div>
                </PasswordGenerateRow>
                <UpdateResendInviteRow>
                  <OutlineButton onClick={onCancelEdit}>
                    {labels.cancel}
                  </OutlineButton>
                  <CommonButton
                    sx={{ marginLeft: 1 }}
                    onClick={onUpdateAndResendButtonPressed}
                  >
                    {labels.updateAndResendInvite}
                  </CommonButton>
                  <CommonButton
                    disabled={shouldDisable}
                    sx={{ marginLeft: 1 }}
                    style={{
                      backgroundColor: shouldDisable ? "#DCCDE3" : "#2E1A46",
                      color: "white",
                    }}
                    onClick={() => onUpdateButtonPressed(false)}
                  >
                    {labels.update}
                  </CommonButton>
                </UpdateResendInviteRow>
              </InformationContainer>
            ) : (
              <InformationContainer>
                <TitleRow>
                  <TitleStatus
                    style={isSmallUI ? { flexDirection: "column" } : {}}
                  >
                    <OperatorTitle>{operator.organization}</OperatorTitle>
                    <div
                      style={
                        isSmallUI ? { marginTop: 8 } : statusContainerStyle
                      }
                    >
                      {operator.status === FilterStatus.PendingApproval ? (
                        <StatusOperator type={FilterStatus.PendingApproval} />
                      ) : operator.status === FilterStatus.Rejected ? (
                        <StatusOperator type={FilterStatus.Rejected} />
                      ) : (
                        <Status isEnable={operator.isActive}></Status>
                      )}
                    </div>
                  </TitleStatus>
                  <TextButton onClick={onEditButtonPressed}>Edit</TextButton>
                </TitleRow>
                <Divider />
                <SubtitleText>{labels.operatorRepresentative}</SubtitleText>
                <ShortInfoRow
                  style={
                    isSmallUI
                      ? {
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }
                      : {}
                  }
                >
                  <ShortInfo title={labels.fullName} value={operator.name} />
                  <ShortInfo
                    style={isSmallUI ? { marginTop: 20 } : {}}
                    title={labels.email}
                    value={operator.email || ""}
                  />
                  {operator.status !== FilterStatus.PendingApproval ? (
                    <ShortInfo
                      style={isSmallUI ? { marginTop: 20 } : {}}
                      title={labels.password}
                      value={"**********"}
                    />
                  ) : (
                    <div style={{ width: 100 }}></div>
                  )}
                </ShortInfoRow>
                <ControlRow
                  operator={operator}
                  onApproveButtonPressed={onApproveButtonPressed}
                  onRejectButtonPressed={onRejectButtonPressed}
                  onStatusChange={onStatusChange}
                />
                {operator.status === FilterStatus.Rejected &&
                  operator.rejectReason !== undefined && (
                    <RejectedReason reason={operator.rejectReason || ""} />
                  )}
                <AddedByText
                  style={
                    isSmallUI
                      ? {
                          position: "relative",
                          marginTop: 48,
                          textAlign: "left",
                          bottom: 0,
                        }
                      : {}
                  }
                >
                  Added by: {operator.addedBy}
                </AddedByText>
              </InformationContainer>
            )}
            {serverListLoaded && (
              <>
                <Headline>{labels.serversList}</Headline>
                <ServerContainer>
                  <TabContext value={currentFilter.toString()}>
                    <TabList
                      variant="scrollable"
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                    >
                      <CustomTab
                        label={`${labels.allServers} (${serverList.length})`}
                        value={FilterStatus.Total.toString()}
                      />
                      <CustomTab
                        label={`${labels.courseCompleted} (${courseCompleted.length})`}
                        value={`${FilterStatus.Completed.toString()}`}
                      />
                      <CustomTab
                        label={`${labels.inProgress} (${inProgress.length})`}
                        value={FilterStatus.InProgress.toString()}
                      />
                      <CustomTab
                        label={`${labels.notStarted} (${notStarted.length})`}
                        value={FilterStatus.NotStarted.toString()}
                      />
                      <CustomTab
                        label={`${labels.notJoined} (${notJoined.length})`}
                        value={FilterStatus.NotJoined.toString()}
                      />
                    </TabList>
                    <CustomTabPanel
                      value={FilterStatus.Total.toString()}
                      list={serverList}
                    />
                    <CustomTabPanel
                      value={FilterStatus.Completed.toString()}
                      list={courseCompleted}
                    />
                    <CustomTabPanel
                      value={FilterStatus.InProgress.toString()}
                      list={inProgress}
                    />
                    <CustomTabPanel
                      value={FilterStatus.NotStarted.toString()}
                      list={notStarted}
                    />
                    <CustomTabPanel
                      value={FilterStatus.NotJoined.toString()}
                      list={notJoined}
                    />
                  </TabContext>
                </ServerContainer>
              </>
            )}
          </>
        )}
      </Content>
    </Container>
  );
};

const statusContainerStyle = {
  marginLeft: 16,
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
};

const generateButtonStyle = {
  height: 40,
  width: 120,
  fontSize: 14,
  fontWeight: 700,
  fontFamily: "Mulish",
  color: "#6E4C8E",
};

const ServerDialogControlRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 32px;
`;

const EditServerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 544px;
  background: #ffffff;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
  padding: 24px;
`;

const AddedByText = styled.div`
  position: absolute;
  bottom: 32px;
  right: 32px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: #918f95;
`;

const UpdateResendInviteRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 48px;
`;

const cellStyle = {
  color: "#000000",
  fontFamily: "Mulish",
  fontWeight: 400,
  fontSize: 14,
  height: 44,
};

const TableCellCustomStyle = {
  color: "#81859A",
  fontFamily: "Mulish",
  fontWeight: 700,
  fontSize: 12,
  textTransform: "uppercase",
};

const CustomTableRow = styled(TableRow)`
  padding: 12px 24px;
  height: 44px;
  background-color: #f9fafb;
  box-shadow: none;
`;

const ServerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
`;

const CustomInputInfo = styled.div`
  margin-top: 16px;
`;

const ShortInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  max-width: 700px;
`;

const SubtitleText = styled.div`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #2e1a46;
  margin-top: 24px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ebebeb;
  margin-top: 12px;
`;

const TitleStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const OperatorTitle = styled.div`
  font-family: Mulish;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #2e1a46;
`;

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  padding-top: 12px;
  padding-left: 32px;
  padding-right: 32px;
  background-color: #ffffff;
  margin-top: 16px;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
  position: relative;
`;

const Headline = styled.div`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #2e1a46;
  margin-top: 48px;
`;

const DialogTitle = styled.div`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #2e1a46;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export default OperatorDetail;

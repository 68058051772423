import { createSlice } from "@reduxjs/toolkit";
import { Tab } from "../../constants/types";

const initialState = {
  currentActiveTab: Tab.Operators,
  currentSuperAdminApprovalTabIndex: 0,
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setCurrentActiveTab: (state, action) => {
      state.currentActiveTab = action.payload;
    },
    setCurrentSuperAdminApprovalTabIndex: (state, action) => {
      state.currentSuperAdminApprovalTabIndex = action.payload;
    },
  },
});

export const { setCurrentActiveTab, setCurrentSuperAdminApprovalTabIndex } =
  commonSlice.actions;

export default commonSlice.reducer;

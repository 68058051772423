import React, { FC } from "react";
import styled from "@emotion/styled";
import { labels } from "../../constants/labels";

interface Props {
  reason: string;
}

const RejectedReason: FC<Props> = ({ reason }: Props) => {
  return (
    <div style={{ flexDirection: "column", display: "flex" }}>
      <Title>{labels.reason}</Title>
      <Reason>{reason}</Reason>
    </div>
  );
};

const Title = styled.label`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #2e1a46;
`;

const Reason = styled.label`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-top: 12px;
`;

export default RejectedReason;

import React, { FC, useState, useEffect, useCallback, useMemo } from "react";
import Header from "../../components/Header";
import styled from "@emotion/styled";
import {
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Backdrop,
  CircularProgress,
  Tabs,
} from "@mui/material";
import { useAlert } from "react-alert";

import TabPanel from "@mui/lab/TabPanel";
import { TabContext } from "@mui/lab";
import { RootState, useAppDispatch } from "../../store";
import {
  changeOperatorApprovalStatus,
  editOperatorStatus,
  fetchOperators,
  resendInvitation,
  setEditingOperatorId,
  setEditingOperatorName,
} from "../../features/operator/operatorSlice";
import { useSelector } from "react-redux";
import Status from "../../components/Status";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import OutlineButton from "../../components/OutlineButton";
import { labels } from "../../constants/labels";
import FillButton from "../../components/FillButton";
import { OperatorStatus, Role } from "../../constants/types";
import Dialog from "../../components/Dialog";
import SuperAdminHome from "./superAdminHome";
import Reason from "./reason";
import { CustomTab } from "../../constants/styles";
import { formatDate } from "../../constants/utils";
import MoreButton from "../../components/MoreButton";
import { Operator } from "../../features/operator/operatorSlice";

enum FilterStatus {
  ALL = "all",
  ENABLED = "enabled",
  DISABLED = "disabled",
}

const options = [
  { value: FilterStatus.ALL, label: "All" },
  { value: FilterStatus.ENABLED, label: "Enabled" },
  { value: FilterStatus.DISABLED, label: "Disabled" },
];
const Management: FC = () => {
  const dispatch = useAppDispatch();
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const operators = useSelector(
    (state: RootState) => state.operator.operators || []
  );
  const [currentOptionIndex, setCurrentOptionIndex] = useState<string>("0");
  const [currentOperatorApprovalTabIndex, setCurrentOperatorApprovalTabIndex] =
    useState<number>(0);
  const [isDisableConfirmationModalOpen, setIsDisableConfirmationModalOpen] =
    useState<boolean>(false);
  const [isApproveConfirmationModalOpen, setIsApproveConfirmationModalOpen] =
    useState<boolean>(false);
  const [isRejectConfirmationModalOpen, setIsRejectConfirmationModalOpen] =
    useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState(options[1]);
  const [currentChoosingUser, setCurrentChoosingUser] = useState(null);
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.user);
  const handleChange = (selectedOption: any) => {
    setSelectedOption(selectedOption);
  };
  const [isSmallUI, setIsSmallUI] = useState(window.innerWidth < 768);
  const [rejectReason, setRejectReason] = useState<string>("");

  useEffect(() => {
    const handleResize = () => setIsSmallUI(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchOperatorsList = useCallback(
    (successCallback?: () => void) => {
      dispatch(
        fetchOperators({
          successCallback: () => {
            setIsLoading(false);
            successCallback && successCallback();
          },
        })
      );
    },
    [dispatch]
  );

  const onTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedOption(options[0]);
    setCurrentOperatorApprovalTabIndex(parseInt(newValue));
  };

  useEffect(() => {
    if (operators.length === 0 && !isLoading) {
      setIsLoading(true);
      fetchOperatorsList();
    }
  }, [dispatch, fetchOperatorsList, isLoading, operators]);

  const filteredOperators = useMemo(() => {
    let resultFilteredOperators = operators;
    switch (currentOperatorApprovalTabIndex) {
      case 0:
        resultFilteredOperators = operators.filter(
          (operator) => operator["status"] === OperatorStatus.Approved
        );
        break;
      case 1:
        resultFilteredOperators = operators.filter(
          (operator) => operator["status"] === OperatorStatus.Pending
        );
        break;
      case 2:
        resultFilteredOperators = operators.filter(
          (operator) => operator["status"] === OperatorStatus.Rejected
        );
        break;
      default:
        break;
    }
    if (selectedOption.value === FilterStatus.ENABLED) {
      resultFilteredOperators = resultFilteredOperators.filter(
        (operator: any) =>
          operator["isActive"] && operator["status"] === OperatorStatus.Approved
      );
    } else if (selectedOption.value === FilterStatus.DISABLED) {
      resultFilteredOperators = resultFilteredOperators.filter(
        (operator) =>
          !operator["isActive"] ||
          operator["status"] !== OperatorStatus.Approved
      );
    }
    return resultFilteredOperators;
  }, [operators, currentOperatorApprovalTabIndex, selectedOption]);

  const editingOperatorId = useSelector(
    (state: RootState) => state.operator.editingOperatorId
  );
  const editingOperatorName = useSelector(
    (state: RootState) => state.operator.editingOperatorName
  );

  const addOperator = () => {
    navigate("/add-operator");
  };

  const onCancelDisableConfirmationModal = () => {
    dispatch(
      setEditingOperatorId({
        id: null,
      })
    );
    setIsDisableConfirmationModalOpen(false);
    setIsLoading(false);
  };

  const showDisableAlert = (row: Operator) => {
    dispatch(
      setEditingOperatorId({
        id: row["id"],
      })
    );
    dispatch(
      setEditingOperatorName({
        name: row["name"],
      })
    );
    setIsDisableConfirmationModalOpen(true);
  };
  const changeToEnableStatus = (row: Operator) =>
    changeOperatorStatus(row["id"], row["name"], true);

  const renderOptions = (row: any) => {
    if (
      currentOperatorApprovalTabIndex === 1 ||
      currentOperatorApprovalTabIndex === 2
    ) {
      return [
        {
          label: labels.view,
          onClick: () => navigate(`/operator-detail/${row["id"]}`),
        },
      ];
    }
    if (user && user["role"] === Role.Salesperson) {
      return [
        {
          label: labels.view,
          onClick: () => navigate(`/operator-detail/${row["id"]}`),
        },
        {
          label: labels.resendInvite,
          onClick: () => onResendInviteButtonPressed(row["id"]),
        },
      ];
    }
    return [
      {
        label: labels.view,
        onClick: () => navigate(`/operator-detail/${row["id"]}`),
      },
      {
        label: row["isActive"] ? labels.disable : labels.enable,
        onClick: () => {
          row["isActive"] ? showDisableAlert(row) : changeToEnableStatus(row);
        },
      },
      {
        label: labels.resendInvite,
        onClick: () => {
          onResendInviteButtonPressed(row["id"]);
        },
      },
    ];
  };
  const changeOperatorStatus = useCallback(
    (operatorId: string, operatorName: string, status: boolean) => {
      setIsDisableConfirmationModalOpen(false);
      setIsLoading(true);
      dispatch(
        setEditingOperatorId({
          id: operatorId,
        })
      );
      dispatch(
        setEditingOperatorName({
          name: operatorName,
        })
      );
      dispatch(
        editOperatorStatus({
          operatorId: operatorId || "",
          status: status,
          onSuccess: () => {
            fetchOperatorsList(() => {
              dispatch(
                setEditingOperatorId({
                  id: null,
                })
              );
              dispatch(
                setEditingOperatorName({
                  name: null,
                })
              );
              alert.success(
                `${operatorName} has been ${status ? "enabled" : "disabled"}`
              );
              setIsLoading(false);
            });
          },
        })
      );
    },
    [alert, dispatch, fetchOperatorsList]
  );

  const onApproveOperator = () => {
    setIsLoading(true);
    dispatch(
      changeOperatorApprovalStatus({
        email: currentChoosingUser ? currentChoosingUser["email"] : "",
        status: OperatorStatus.Approved,
        successCallback: () => {
          fetchOperatorsList();
          setIsApproveConfirmationModalOpen(false);
          setIsLoading(false);
          setCurrentChoosingUser(null);
          currentChoosingUser &&
            alert.success(
              `${currentChoosingUser["organization"]} has been approved and enabled.`
            );
        },
      })
    );
  };

  const onRejectOperator = () => {
    setIsLoading(true);
    dispatch(
      changeOperatorApprovalStatus({
        email: currentChoosingUser ? currentChoosingUser["email"] : "",
        rejectReason: rejectReason,
        status: OperatorStatus.Rejected,
        successCallback: () => {
          fetchOperatorsList();
          setIsRejectConfirmationModalOpen(false);
          setIsLoading(false);
          setCurrentChoosingUser(null);
          currentChoosingUser &&
            alert.success(
              `${currentChoosingUser["organization"]} has been rejected.`
            );
        },
      })
    );
  };

  const onResendInviteButtonPressed = (id: string) => {
    alert.show("Resending invite", {
      type: "info",
    });
    dispatch(
      resendInvitation({
        operatorId: id,
        successCallback: () =>
          alert.show("An invite has been resent successfully", {
            type: "success",
          }),
        errorCallback: () =>
          alert.show("Resending Invitation failed", {
            type: "error",
          }),
      })
    );
  };

  const onDisableAgree = useCallback(() => {
    changeOperatorStatus(
      editingOperatorId || "",
      editingOperatorName || "",
      false
    );
  }, [changeOperatorStatus, editingOperatorName, editingOperatorId]);

  const renderSelectOptions = () => (
    <div style={{ width: 128, marginBottom: 12, marginTop: 24 }}>
      <Select
        styles={{
          control: (provided) => ({
            ...provided,
            ...SelectStyle,
          }),
        }}
        value={selectedOption}
        onChange={handleChange}
        options={options}
      />
    </div>
  );

  return (
    <Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MuiDialog
        open={isDisableConfirmationModalOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogCustomTitle id="alert-dialog-title">
          {labels.confirmation}
        </DialogCustomTitle>
        <DialogContent>
          <DialogCustomContentText id="alert-dialog-description">
            {labels.disableConfirmationModal}
          </DialogCustomContentText>
        </DialogContent>
        <DialogActions sx={{ marginBottom: 1 }}>
          <OutlineButton onClick={onCancelDisableConfirmationModal}>
            {labels.cancelOption}
          </OutlineButton>
          <div style={{ marginLeft: 12 }}>
            <FillButton onClick={onDisableAgree}>
              {labels.agreeOption}
            </FillButton>
          </div>
        </DialogActions>
      </MuiDialog>
      <MuiDialog
        open={isApproveConfirmationModalOpen || isRejectConfirmationModalOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Dialog
          title={labels.confirmation}
          content={
            isApproveConfirmationModalOpen
              ? `${labels.approveAdminConfirmation}`
              : `${labels.rejectAdminConfirmation}`
          }
          declineText={labels.cancelDeleteAdmin}
          onDecline={() => {
            setIsApproveConfirmationModalOpen(false);
            setIsRejectConfirmationModalOpen(false);
          }}
          acceptText={
            isApproveConfirmationModalOpen
              ? labels.yesApprove
              : labels.yesReject
          }
          onAccept={() => {
            isApproveConfirmationModalOpen
              ? onApproveOperator()
              : onRejectOperator();
          }}
        >
          {isRejectConfirmationModalOpen && (
            <Reason
              rejectReason={rejectReason}
              setRejectReason={setRejectReason}
            />
          )}
        </Dialog>
      </MuiDialog>
      <Header isSmallUI={isSmallUI} />
      <Content style={isSmallUI ? smallIUContentStyle : {}}>
        <TitleRow>
          <OperatorText>Operator</OperatorText>
          <AddButton sx={{ marginLeft: 1 }} onClick={addOperator}>
            Add Operator
          </AddButton>
        </TitleRow>
        {operators.length === 0 || !user ? (
          <LinearProgress color="secondary" />
        ) : (
          <>
            {user && user["role"] === Role.SuperAdmin ? (
              <SuperAdminHome
                currentOptionIndex={currentOptionIndex}
                operators={operators}
                setCurrentChoosingUser={setCurrentChoosingUser}
                setIsApproveConfirmationModalOpen={
                  setIsApproveConfirmationModalOpen
                }
                setIsRejectConfirmationModalOpen={
                  setIsRejectConfirmationModalOpen
                }
                setSelectedOption={(optionIndex: number) =>
                  setSelectedOption(options[optionIndex])
                }
                renderSelectOptions={renderSelectOptions}
                selectedOption={selectedOption}
                showDisableAlert={showDisableAlert}
                changeToEnableStatus={changeToEnableStatus}
                onResendInviteButtonPressed={onResendInviteButtonPressed}
              />
            ) : (
              <TabContext value={currentOptionIndex}>
                <Tabs
                  value={currentOperatorApprovalTabIndex}
                  onChange={onTabChange}
                  aria-label="basic tabs example"
                >
                  <CustomTab label={labels.approved} />
                  <CustomTab label={labels.pendingApproval} />
                  <CustomTab label={labels.rejected} />
                </Tabs>
                {currentOperatorApprovalTabIndex === 0 && renderSelectOptions()}
                <TabPanel value="0" sx={{ paddingLeft: 0, paddingRight: 0 }}>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell sx={tableHeaderCellStyles}>
                            OPERATOR NAME
                          </TableCell>
                          <TableCell sx={tableHeaderCellStyles}>
                            OPERATOR REPRESENTATIVE NAME
                          </TableCell>
                          <TableCell sx={tableHeaderCellStyles}>
                            ADDED BY
                          </TableCell>
                          {currentOperatorApprovalTabIndex === 0 && (
                            <TableCell sx={tableHeaderCellStyles}>
                              STATUS
                            </TableCell>
                          )}
                          <TableCell sx={tableHeaderCellStyles}>
                            {currentOperatorApprovalTabIndex === 0
                              ? "APPROVED DATE"
                              : currentOperatorApprovalTabIndex === 1
                              ? "ADDED DATE"
                              : "REJECTED DATE"}
                          </TableCell>
                          <TableCell sx={tableHeaderCellStyles}></TableCell>
                        </TableRow>
                      </TableHead>
                      {operators.length === 0 ? null : (
                        <TableBody>
                          {filteredOperators.map((row, index) => {
                            return (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                  "&:hover": { backgroundColor: "#F4F4FE;" },
                                  backgroundColor: "#fff",
                                }}
                              >
                                <TableCell
                                  onClick={() =>
                                    navigate(`/operator-detail/${row["id"]}`)
                                  }
                                  sx={[tableCellStyles, { cursor: "pointer" }]}
                                >
                                  {row["organization"]}
                                </TableCell>
                                <TableCell sx={tableCellStyles}>
                                  {row["name"]}
                                </TableCell>
                                <TableCell sx={tableCellStyles}>
                                  {row["addedBy"]}
                                </TableCell>
                                {currentOperatorApprovalTabIndex === 0 && (
                                  <TableCell sx={tableCellStyles}>
                                    <Status
                                      isEnable={
                                        row["isActive"] &&
                                        row["status"] ===
                                          OperatorStatus.Approved
                                      }
                                    />
                                  </TableCell>
                                )}
                                <TableCell sx={tableCellStyles}>
                                  {formatDate(
                                    new Date(
                                      row["updateDate"] || row["dateAdded"]
                                    )
                                  )}
                                </TableCell>
                                <TableCell sx={tableCellStyles}>
                                  <MoreButton options={renderOptions(row)} />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </TabPanel>
              </TabContext>
            )}
          </>
        )}
      </Content>
    </Container>
  );
};

const smallIUContentStyle = {
  padding: "16px 24px",
};

const SelectStyle = {
  width: 128,
  height: 40,
  borderRadius: 8,
  borderColor: "#D0D5DD",
};

export const tableHeaderCellStyles = {
  backgroundColor: "#F9FAFB",
  color: "#81859A",
  fontFamily: "Mulish",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  height: 44,
};

export const tableCellStyles = {
  height: 48,
  fontFamily: "Mulish",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 16,
};

const DialogCustomContentText = styled(DialogContentText)`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

const DialogCustomTitle = styled(DialogTitle)`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #2e1a46;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

const AddButton = styled(Button)`
  background: #2e1a46;
  border-radius: 8px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: capitalize;
  padding: 10px 16px;
  &:hover {
    background: #6e4c8e;
  },
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
`;

const OperatorText = styled.div`
  font-family: "Mulish";
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #2e1a46;
  display: flex;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 40px;
  padding-left: 112px;
  padding-right: 112px;
  padding-bottom: 112px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
`;

export default Management;

import { TextField } from "@mui/material";
import React from "react";

interface Props {
  rejectReason: string;
  setRejectReason: (reason: string) => void;
}

const ReasonInput: React.FC<Props> = ({
  rejectReason,
  setRejectReason,
}: Props) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRejectReason(e.target.value);
  };

  return (
    <TextField
      sx={{ marginTop: 1 }}
      multiline={true}
      color="secondary"
      id="outlined-basic"
      variant="outlined"
      size="small"
      value={rejectReason}
      onChange={onChange}
      placeholder={"Please enter the reason"}
    />
  );
};

export default ReasonInput;

import { Container, Content } from "../management/management.page";
import Header from "../../components/Header";
import {
  AddOperatorText,
  BackButton,
  BackText,
  ContentContainer,
  Footer,
  FullName,
  InputColumn,
  TitleText,
} from "../../constants/styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate, useParams } from "react-router-dom";
import { labels } from "../../constants/labels";
import {
  Button,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState, useEffect, useMemo } from "react";
import { Role } from "../../constants/types";
import generator from "generate-password";
import Select from "react-select";
import { useAppDispatch } from "../../store";
import {
  changePassword,
  getAdmin,
  updateAdmin,
} from "../../features/admin/adminSlices";
import FillButton from "../../components/FillButton";
import { useAlert } from "react-alert";

const Options = [
  { value: Role.Admin, label: labels.admin },
  { value: Role.Salesperson, label: labels.salesperson },
];

const EditAdmin = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isChanged, setIsChanged] = useState(false);
  const [currentAdmin, setCurrentAdmin] = useState(null);
  const [currentRoleOption, setCurrentRoleOption] = useState(Options[0]);
  const alert = useAlert();

  useEffect(() => {
    if (currentAdmin) {
      if (
        firstName !== currentAdmin["firstName"] ||
        lastName !== currentAdmin["lastName"] ||
        email !== currentAdmin["email"] ||
        password !== currentAdmin["password"] ||
        currentRoleOption.value !== currentAdmin["role"]
      ) {
        setIsChanged(true);
      } else {
        setIsChanged(false);
      }
    }
  }, [
    currentAdmin,
    currentRoleOption.value,
    email,
    firstName,
    lastName,
    password,
  ]);

  const isEmpty = useMemo(
    () =>
      firstName === "" || lastName === "" || email === "" || password === "",
    [email, firstName, lastName, password]
  );

  useEffect(() => {
    if (!currentAdmin) {
      dispatch(
        getAdmin({
          email: params.email || "",
          successCallback: (admin) => {
            setFirstName(admin.firstName);
            setLastName(admin.lastName);
            setEmail(admin.email);
            setPassword(admin.password);
            setCurrentAdmin(admin);
            setCurrentRoleOption(
              Options.find((option) => option.value === admin.role) ||
                Options[0]
            );
          },
          errorCallback: () => {},
        })
      );
    }
  }, [currentAdmin, dispatch, params.email]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onSelectChange = (selectedOption: any) => {
    setCurrentRoleOption(selectedOption);
  };

  const onGeneratePassword = () => {
    const initialPassword = generator.generate({
      length: 6,
      numbers: false,
      uppercase: true,
      lowercase: false,
    });
    setPassword(initialPassword);
  };

  const navigate = useNavigate();

  const onUpdatePressed = () => {
    if (currentAdmin && password !== currentAdmin["password"]) {
      dispatch(
        changePassword({
          password: password,
          email: currentAdmin["email"],
        })
      );
    }
    dispatch(
      updateAdmin({
        email,
        firstName,
        lastName,
        role: currentRoleOption.value,
        password,
        callback: () => {
          // navigate(-1);
          displayPopup();
        },
      })
    );
  };

  const displayPopup = () => {
    alert.success(labels.updateAdminSuccess);
  };

  return (
    <Container>
      <Header />
      <Content>
        <div>
          <BackButton variant="text" onClick={() => navigate(-1)}>
            <ArrowBackIosIcon fontSize={"small"} color="secondary" />
            <BackText>Back to Admin User List</BackText>
          </BackButton>
        </div>
        <AddOperatorText>{labels.editAdminUser}</AddOperatorText>
        {!currentAdmin ? (
          <LinearProgress sx={{ marginTop: 3 }} color="secondary" />
        ) : (
          <ContentContainer>
            <FullName>
              <InputColumn>
                <TitleText>{labels.firstName}</TitleText>
                <TextField
                  sx={{ marginTop: 1 }}
                  color="secondary"
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder={labels.enterFirstName}
                />
              </InputColumn>
              <InputColumn style={{ marginLeft: 20 }}>
                <TitleText>{labels.lastName}</TitleText>
                <TextField
                  sx={{ marginTop: 1 }}
                  color="secondary"
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder={labels.enterLastName}
                />
              </InputColumn>
            </FullName>
            <FullName style={{ marginTop: 16 }}>
              <InputColumn>
                <TitleText>{labels.email}</TitleText>
                <TextField
                  sx={{ marginTop: 1 }}
                  color="secondary"
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={labels.enterEmail}
                />
              </InputColumn>
              <InputColumn style={{ marginLeft: 20 }}>
                <TitleText>{labels.password}</TitleText>
                <TextField
                  type={showPassword ? "text" : "password"}
                  sx={{ marginTop: 1 }}
                  color="secondary"
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </InputColumn>
              <div style={{ marginLeft: 12, marginTop: 27 }}>
                <Button
                  onClick={onGeneratePassword}
                  variant="outlined"
                  sx={{
                    height: 40,
                    width: 120,
                    fontSize: 14,
                    fontWeight: 700,
                    fontFamily: "Mulish",
                    color: "#6E4C8E",
                  }}
                >
                  Generate
                </Button>
              </div>
            </FullName>
            <TitleText style={{ marginTop: 20 }}>{labels.role}</TitleText>
            <div style={{ width: "30%" }}>
              <Select
                styles={{
                  control: (provided) => ({
                    ...provided,
                    height: 40,
                    borderRadius: 4,
                    borderColor: "#D0D5DD",
                    marginTop: 6,
                  }),
                }}
                value={currentRoleOption}
                onChange={onSelectChange}
                options={Options}
              />
            </div>
          </ContentContainer>
        )}
      </Content>
      <Footer>
        <FillButton
          isDisabled={!isChanged || isEmpty}
          onClick={onUpdatePressed}
        >
          {labels.update}
        </FillButton>
      </Footer>
    </Container>
  );
};

export default EditAdmin;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { OperatorStatus, Role } from "../../constants/types";
import { operatorApp } from "../../realm/store";
import { PREFIX } from "../../constants/labels";

export type Operator = {
  organization: string;
  name: string;
  isActive: boolean;
  id: string;
  email?: string;
  password?: string;
  status?: string;
  addedBy?: string;
  rejectReason?: string;
  updateDate?: string;
  dateAdded?: string;
};

type State = {
  operators: Operator[] | null;
  isLoading: boolean;
  editingOperatorId: string | null;
  editingOperatorName: string | null;
};

const initialState = {
  operators: null,
  isLoading: false,
  editingOperatorId: null,
  editingOperatorName: null,
};

type OperatorStatusParams = {
  operatorId: string;
  status: boolean;
  onSuccess?: () => void;
};

type NewOperatorParams = {
  organization: string;
  name: string;
  email: string;
  password: string;
  role: string;
  addedBy: string;
  addedByEmail: string;
  adminId: string;
  adminRole: string;
  callback?: (result: any) => void;
  errorHandler?: (err: any) => void;
};

type OperatorParams = {
  operatorId: string;
  successCallback?: (data: Operator) => void;
  errorCallback?: (err: any) => void;
};

type GetServersParams = {
  operatorId: string;
  successCallback?: (data: any) => void;
};

type UpdateOperatorParams = {
  operatorId: string;
  operatorData: any;
  isUpdatingEmail: boolean;
  isUpdatingPassword: boolean;
  successCallback?: () => void;
};

type ResendInvitationParams = {
  operatorId: string;
  successCallback?: () => void;
  errorCallback?: (err: any) => void;
};

type FetchOperatorParams = {
  successCallback?: () => void;
  errorCallback?: (err: any) => void;
};

type ChangeOperatorStatusParams = {
  status: OperatorStatus;
  email: string;
  rejectReason?: string;
  successCallback?: () => void;
  errorCallback?: (err: any) => void;
};

type UpdateServerParams = {
  serverId: string;
  serverData: any;
  successCallback?: () => void;
};

type ResendServerInvitationParams = {
  serverId: string;
  successCallback?: () => void;
  errorCallback?: (err: any) => void;
};

export const resendInvitation = createAsyncThunk(
  "operator/resendInvitation",
  async ({
    operatorId,
    successCallback,
    errorCallback,
  }: ResendInvitationParams) => {
    try {
      const data = await axios.get(
        `${PREFIX}/api/operator/get-operator/${operatorId}`
      );
      const operator: Operator = {
        organization: data.data.operator["operator/organization"],
        name: data.data.operator["operator/name"],
        isActive: data.data.operator["operator/active?"],
        id: data.data.operator["_id"],
        email: data.data.operator["operator/email"] || "",
        password:
          data.data.operator["operator/displayPassword"] ||
          data.data.operator["operator/initialPass"] ||
          "",
      };
      await axios.post(`${PREFIX}/api/operator/resend-invite`, {
        name: operator.name,
        email: operator.email,
        password: operator.password,
      });
      successCallback && successCallback();
    } catch (err) {
      errorCallback && errorCallback(err);
    }
  }
);

export const updateOperator = createAsyncThunk(
  "user/updateOperator",
  async ({
    operatorData,
    operatorId,
    isUpdatingEmail,
    isUpdatingPassword,
    successCallback,
  }: UpdateOperatorParams) => {
    try {
      const { password, organization, name, email } = operatorData;
      if (isUpdatingEmail) {
        try {
          await operatorApp.emailPasswordAuth.registerUser({
            email: operatorData.email,
            password: operatorData.password,
          });
        } catch (e) {}
      }
      await operatorApp.emailPasswordAuth
        .callResetPasswordFunction({ email: email, password: password })
        .then(() => {})
        .catch(() => {});

      await axios.put(`${PREFIX}/api/operator/update-operator`, {
        password: password,
        operatorId: operatorId,
        email: email,
        name: name,
        organization: organization,
      });
      successCallback && successCallback();
    } catch (err) {}
  }
);

export const addOperator = createAsyncThunk(
  "operator/addOperator",
  async (operatorData: NewOperatorParams) => {
    try {
      const result = await axios.post(`${PREFIX}/api/operator/add-operator`, {
        organization: operatorData.organization,
        name: operatorData.name,
        email: operatorData.email,
        password: operatorData.password,
        role: operatorData.role,
        status:
          operatorData.adminRole === Role.SuperAdmin
            ? OperatorStatus.Approved
            : OperatorStatus.Pending,
        addedBy: operatorData.addedBy,
        adminId: operatorData.adminId,
        addedByEmail: operatorData.addedByEmail,
        adminRole: operatorData.adminRole,
        dateAdded: new Date(),
      });
      await operatorApp.emailPasswordAuth.registerUser({
        email: operatorData.email,
        password: operatorData.password,
      });
      operatorData.callback && operatorData.callback(result.data.operator);
    } catch (err) {
      console.log("Failed to add operator", err);
      operatorData.errorHandler &&
        operatorData.errorHandler(
          (err as any)?.error ||
            (err as any)?.response?.data.message ||
            "An error occurred"
        );
    }
  }
);

export const changeOperatorApprovalStatus = createAsyncThunk(
  "operator/changeOperatorApprovalStatus",
  async ({
    status,
    email,
    rejectReason,
    successCallback,
    errorCallback,
  }: ChangeOperatorStatusParams) => {
    try {
      await axios.put(
        `${PREFIX}/api/operator/change-operator-approval-status`,
        {
          status,
          email,
          rejectReason,
          updateDate: new Date(),
        }
      );
      successCallback && successCallback();
    } catch (err) {
      errorCallback && errorCallback(err);
    }
  }
);

export const editOperatorStatus = createAsyncThunk(
  "operator/editOperatorStatus",
  async (params: OperatorStatusParams) => {
    try {
      await axios.put(`${PREFIX}/api/operator/update-operator-status`, {
        operatorId: params.operatorId,
        status: params.status,
      });
      params.onSuccess && params.onSuccess();
    } catch (err) {
      console.error("Failed to update operator status", err);
    }
  }
);

export const fetchOperators = createAsyncThunk(
  "operator/fetchOperators",
  async (params: FetchOperatorParams = {}) => {
    const { successCallback, errorCallback } = params;
    try {
      const data = await axios.get(`${PREFIX}/api/operator/get-operators`);
      successCallback && successCallback();
      return data;
    } catch (err) {
      console.error("Failed to fetch operator", err);
      errorCallback && errorCallback(err);
    }
  }
);

export const resendServerInvitation = createAsyncThunk(
  "server/resendServerInvitation",
  async (params: ResendServerInvitationParams) => {
    try {
      await axios.post(`${PREFIX}/api/servers/resend-invitation`, {
        serverId: params.serverId,
      });
      params.successCallback && params.successCallback();
    } catch (err) {
      console.error("Failed to resend server invitation", err);
    }
  }
);

export const fetchOperator = createAsyncThunk(
  "operator/get-operator",
  async ({ operatorId, successCallback, errorCallback }: OperatorParams) => {
    try {
      const data = await axios.get(
        `${PREFIX}/api/operator/get-operator/${operatorId}`
      );
      const operator: Operator = {
        organization: data.data.operator["operator/organization"],
        name: data.data.operator["operator/name"],
        isActive: data.data.operator["operator/active?"],
        id: data.data.operator["_id"],
        email: data.data.operator["operator/email"] || "",
        password:
          data.data.operator["operator/displayPassword"] ||
          data.data.operator["operator/initialPass"] ||
          "",
        status: data.data.operator["status"],
        addedBy: data.data.operator["addedBy"],
        rejectReason: data.data.operator["rejectReason"],
      };
      successCallback && successCallback(operator);
    } catch (err) {
      console.error("Failed to fetch operator", err);
    }
  }
);

export const getServers = createAsyncThunk(
  "operator/get-servers",
  async ({ operatorId, successCallback }: GetServersParams) => {
    try {
      const data = await axios.post(`${PREFIX}/api/servers/get-servers`, {
        operatorId,
        serversType: "total",
      });
      successCallback && successCallback(data.data.servers);
    } catch (err) {
      console.error("Failed to fetch operator", err);
    }
  }
);

export const updateServer = createAsyncThunk(
  "operator/update-server",
  async ({ serverId, serverData, successCallback }: UpdateServerParams) => {
    try {
      await axios.put(`${PREFIX}/api/servers/update-server`, {
        serverId,
        serverData: {
          first_name: serverData.firstName,
          last_name: serverData.lastName,
          email: serverData.email,
        },
      });
      successCallback && successCallback();
    } catch (err) {
      console.error("Failed to update server", err);
    }
  }
);
export const operatorSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setEditingOperatorId: (state, action) => {
      state.editingOperatorId = action.payload.id;
    },
    setEditingOperatorName: (state, action) => {
      state.editingOperatorName = action.payload.name;
    },
  },
  extraReducers: {
    [fetchOperators.pending.toString()]: (state: State) => {
      state.isLoading = true;
    },
    [fetchOperators.fulfilled.toString()]: (state: State, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.operators = action.payload.data.allOperators.map(
          (operator: any) => {
            return {
              organization: operator["operator/organization"],
              name: operator["operator/name"],
              isActive: operator["operator/active?"],
              id: operator["_id"],
              status: operator["status"],
              addedBy: operator["addedBy"],
              dateAdded: operator["dateAdded"],
              email: operator["operator/email"] || "",
              updateDate: operator["updateDate"],
            };
          }
        );
      }
    },
    [fetchOperators.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      state.editingOperatorId = null;
      state.editingOperatorName = null;
    },
  },
});

export const { setEditingOperatorId, setEditingOperatorName } =
  operatorSlice.actions;

export default operatorSlice.reducer;

export enum Tab {
  Operators = "Operators",
  AdminManagement = "Admin Management",
}

export enum Role {
  Admin = "Admin",
  Operator = "Operator",
  Salesperson = "Salesperson",
  SuperAdmin = "SuperAdmin",
}

export type Admin = {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  invitedDate: string;
  registeredDate: string;
  isArchived?: boolean;
  _id: string;
};

export enum OperatorStatus {
  Pending = "Pending",
  Approved = "Approved",
  Rejected = "Rejected",
}
export enum FilterStatus {
  ALL = "all",
  ENABLED = "enabled",
  DISABLED = "disabled",
}

export const options = [
  { value: FilterStatus.ALL, label: "All" },
  { value: FilterStatus.ENABLED, label: "Enabled" },
  { value: FilterStatus.DISABLED, label: "Disabled" },
];

export const PREFIX = "";
// export const PREFIX = "http://localhost:8000";

export const labels = {
  welcome: "Welcome to Admin Site",
  email: "Email",
  emailPlaceholder: "Enter your email",
  password: "Password",
  passwordPlaceholder: "Enter your password",
  logIn: "Log in",
  backToOperatorList: "Back to Operator List",
  operatorDetail: "Operator Details",
  operatorInformation: "Operator Information",
  operatorRepresentative: "Operator Representative",
  fullName: "Full Name",
  serversList: "Servers List",
  allServers: "All Servers",
  courseCompleted: "Course Completed",
  inProgress: "In Progress",
  notStarted: "Hasn't Started",
  notJoined: "Hasn't Joined",
  status: "Status",
  inviteDate: "Invite Date",
  userRegistered: "User Registered",
  enable: "Enable",
  disable: "Disable",
  operatorName: "Operator name",
  generate: "Generate",
  cancel: "Cancel",
  updateAndResendInvite: "Resend Invite",
  update: "Update",
  cancelOption: "No, Cancel",
  disableConfirmationModal:
    "Are you sure you want to disable this operator? This action will prevent the operator and all servers from accessing Knowbie.",
  agreeOption: "Yes, Disable",
  confirmation: "Confirmation",
  adminManager: "Admin Management",
  adminUserList: "Admin User List",
  addAdminUser: "Add Admin User",
  backToAdminUserList: "Back to Admin User List",
  firstName: "First name",
  lastName: "Last name",
  enterFirstName: "Enter first name",
  enterEmail: "Enter email",
  role: "Role",
  admin: "Admin",
  salesperson: "Salesperson",
  enterLastName: "Enter last name",
  edit: "Edit",
  delete: "Delete",
  editAdminUser: "Edit Admin User",
  updateAdminSuccess: "Admin user information has been updated",
  deleteAdminConfirmation:
    "Are you sure you want to delete this user? This action cannot be undone and the user's information will be deleted permanently.",
  cancelDeleteAdmin: "No, Cancel",
  deleteAdmin: "Yes, Delete",
  approved: "Approved",
  pending: "Pending",
  rejected: "Rejected",
  pendingApproval: "Pending Approval",
  operatorRepresentativeName: "Operator Representative Name",
  addedBy: "Added By",
  dateAdded: "Added Date",
  view: "View",
  approve: "Approve",
  reject: "Reject",
  approveAdminConfirmation:
    "Are you sure you want to approve this operator? This action will enable the operator.",
  yesApprove: "Yes, Approve",
  rejectAdminConfirmation:
    "Are you sure you want to reject this operator? This action will deny them access to Knowbie.",
  yesReject: "Yes, Reject",
  yesUnarchive: "Yes, Unarchive",
  resendInvite: "Resend Invite",
  editServer: "Edit Server",
  close: "Close",
  serverInfoUpdated: "Server information has been updated",
  successResendInviteServer: "An invite has been resent to",
  logOut: "Log Out",
  reason: "Reason",
  dateApproved: "Approved Date",
  rejectedDate: "Rejected Date",
  emailArchivedTitle: "Email has been archived",
  emailArchivedContent: "Do you want to unarchive this account?",
  wrongEmailPassword: "Wrong email or password",
};

import React from "react";
import styled from "@emotion/styled";
import { labels } from "../../../constants/labels";

interface Props {}

const ReasonText: React.FC<Props> = (props) => {
  return <Text>{labels.reason}</Text>;
};

const Text = styled.label`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #81859a;
`;

export default ReasonText;

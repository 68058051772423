import * as React from "react";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import { Dialog, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { RootState, useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import Logo from "../Logo";
import CloseIcon from "@mui/icons-material/Close";
import { labels } from "../../constants/labels";
import { logOut } from "../../features/user/userSlices";
import { useNavigate } from "react-router-dom";

export default function PositionedMenu() {
  const [open, setOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleClick = () => {
    setOpen(true);
  };

  const user = useSelector((state: RootState) => state.user.user);

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    dispatch(logOut());
    navigate("/");
  };

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MenuIcon />
      </Button>
      <Dialog fullScreen open={open}>
        <MenuHeader>
          <Logo />
          <Button onClick={handleClose}>
            <CloseIcon />
          </Button>
        </MenuHeader>
        <MenuContent>
          <MenuName>{user ? user["name"] : ""}</MenuName>
          <MenuRole>
            {user ? `${user["role"] || ""} | ${user["email"]}` : ""}
          </MenuRole>
          <Stroke></Stroke>
          <LogOut onClick={handleLogout}>{labels.logOut}</LogOut>
        </MenuContent>
      </Dialog>
    </div>
  );
}

const LogOut = styled.div`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2e1a46;
  margin-top: 24px;
`;

const Stroke = styled.div`
  border: 1px solid #ebebeb;
  margin-top: 16px;
`;

const MenuRole = styled.div`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;

const MenuName = styled.div`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #2e1a46;
`;

const MenuHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: 64px;
  border: 1px solid #ebebeb;
`;

const MenuContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 24px;
`;

import styled from "@emotion/styled";
import React, { FC, useMemo } from "react";
import { FilterStatus } from ".";

interface Props {
  type: FilterStatus;
}

const getStatus = (status: FilterStatus) => {
  switch (status) {
    case FilterStatus.Completed:
      return {
        title: "Course Completed",
        backgroundColor: "#ecfdf3",
        borderColor: "#008a51",
      };
    case FilterStatus.InProgress:
      return {
        title: "In Progress",
        backgroundColor: "#E0FBFF",
        borderColor: "#0D81A4",
      };
    case FilterStatus.NotStarted:
      return {
        title: "Hasn't Started",
        backgroundColor: "#FFF4E4",
        borderColor: "#D99D00",
      };
    case FilterStatus.PendingApproval:
      return {
        title: "Pending approval",
        backgroundColor: "#FFF4E4",
        borderColor: "#D99D00",
      };
    case FilterStatus.Rejected:
      return {
        title: "Rejected",
        backgroundColor: "#FFEFEC",
        borderColor: "#E95336",
      };
    default:
      return {
        title: "Hasn't joined",
        backgroundColor: "#FFEFEC",
        borderColor: "#E95336",
      };
  }
};

interface Props {
  type: FilterStatus;
}

const Status: FC<Props> = ({ type = FilterStatus.Completed }: Props) => {
  const statusStyle = useMemo(() => getStatus(type), [type]);

  return (
    <Container
      style={{
        backgroundColor: statusStyle.backgroundColor,
        borderColor: statusStyle.borderColor,
        borderWidth: 1,
        borderStyle: "solid",
      }}
    >
      <Label style={{ color: statusStyle.borderColor }}>
        {getStatus(type).title}
      </Label>
    </Container>
  );
};

const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-family: "Mulish", sans-serif;
  text-align: center;
  line-height: 24px;
`;

const Container = styled.div`
  align-items: center;
  justify-content: center;
  padding: 0px 12px;
  border-radius: 16px;
  display: inline-block;
`;

export default Status;

import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../src/features/counter/counterSlice";
import userReducer from "../src/features/user/userSlices";
import { useDispatch } from "react-redux";
import operatorSlice from "./features/operator/operatorSlice";
import commonSlice from "./features/common/commonSlices";
import adminSlice from "./features/admin/adminSlices";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    user: userReducer,
    operator: operatorSlice,
    commonSlice: commonSlice,
    admin: adminSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types
export default store;

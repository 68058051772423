import styled from "@emotion/styled";
import React, { FC } from "react";
import OutlineButton from "../OutlineButton/index";
import FillButton from "../FillButton";

interface Props {
  title: string;
  content: string;
  declineText: string;
  acceptText: string;
  onDecline: () => void;
  onAccept: () => void;
  children?: React.ReactNode;
}

const Dialog: FC<Props> = ({
  title,
  content,
  declineText,
  acceptText,
  onDecline,
  onAccept,
  children,
}: Props) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Content>
        {content}
        <div style={{ height: 8 }}></div>
        {children}
      </Content>
      <ButtonRow>
        <CancelButton onClick={onDecline}>{declineText}</CancelButton>
        <FillButton onClick={onAccept}>{acceptText}</FillButton>
      </ButtonRow>
    </Container>
  );
};

const CancelButton = styled(OutlineButton)``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 544px;
  padding: 24px;
  background-color: #ffffff;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const Title = styled.div`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #2e1a46;
`;

const Content = styled.div`
  margin-top: 8px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;

const ButtonRow = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
`;

export default Dialog;

import { IconButton, Menu, MenuItem } from "@mui/material";
import React, { FC, useEffect } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import styled from "@emotion/styled";

interface Props {
  options: any[];
  isClose?: boolean;
}

const ITEM_HEIGHT = 36;

const MoreButton: FC<Props> = ({ options, isClose = false }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isClose) {
      setAnchorEl(null);
    }
  }, [isClose]);

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={!isClose && open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "130px",
          },
        }}
      >
        {options.map((option) => (
          <CustomMenuItem
            key={option.label}
            onClick={async () => {
              handleClose();
              setTimeout(() => {
                option.onClick();
              }, 150);
            }}
          >
            {option.label}
          </CustomMenuItem>
        ))}
      </Menu>
    </div>
  );
};

const CustomMenuItem = styled(MenuItem)`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;

export default MoreButton;

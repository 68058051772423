import styled from "@emotion/styled";
import { Button, Tab } from "@mui/material";

export const BackButton = styled(Button)`
  margin: 0 auto;
  flex-direction: row;
`;

export const BackText = styled.label`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #6e4c8e;
  margin-left: 8px;
  text-transform: none;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-bottom: 32px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 40px;
  padding-left: 112px;
  padding-right: 112px;
`;

export const Title = styled.label`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #2e1a46;
  margin-top: 16px;
`;

export const TextButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    font-family: Mulish,
    line-height: 24px;
    color: #6E4C8E;
    padding-left: 32px;
    height: 72px;
    cursor: pointer;
`;

export const TitleText = styled.label`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #81859a;
`;

export const FullNameEmail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 28px;
  width: 100%;
`;

export const InputColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;
`;

export const CommonButton = styled(Button)`
  background: #2e1a46;
  border-radius: 8px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: capitalize;
  height: 40px;
  padding: 0 16px;
  &:hover {
    background: #6e4c8e;
  },
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
`;

export const tableHeaderCellStyles = {
  backgroundColor: "#F9FAFB",
  color: "#81859A",
  fontFamily: "Mulish",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  height: 44,
  textTransform: "uppercase",
};

export const AddOperatorText = styled.label`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #2e1a46;
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const ContentContainer = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 24px 32px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
`;

export const PasswordGenerateRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 6px;
  width: 100%;
`;

export const FullName = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 72px;
  background: #ffffff;
  padding-right: 112px;
`;

export const CustomTab = styled(Tab)`
  text-transform: none;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #2e1a46;
`;

import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "@fontsource/mulish";
import "@fontsource/mulish/700.css";
import SignIn from "./pages/signIn/signIn.page";
import { RootState, useAppDispatch } from "./store";
import { useSelector } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";
import { checkSession } from "./features/user/userSlices";
import Management from "./pages/management/management.page";
import styled from "@emotion/styled";
import AddOperator from "./pages/addOperator";
import OperatorDetail from "./pages/operator-detail";
import AdminManagement from "./pages/adminManagement";
import AddAdminUser from "./pages/addAdminUser";
import EditAdmin from "./pages/editAdmin";

function App() {
  const dispatch = useAppDispatch();
  const isCheckingSession = useSelector(
    (state: RootState) => state.user.isCheckingSession
  );
  const user = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    dispatch(checkSession());
  }, [dispatch]);

  return (
    <Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isCheckingSession}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!isCheckingSession &&
        (!user || !user["id"] ? (
          <Routes>
            <Route path="/" element={<SignIn />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/" element={<Management />} />
            <Route path="/admin-management" element={<AdminManagement />} />
            <Route path="/edit-admin/:email" element={<EditAdmin />} />
            <Route path="/add-admin-user" element={<AddAdminUser />} />
            <Route path="/add-operator" element={<AddOperator />} />
            <Route
              path="/operator-detail/:operatorId"
              element={<OperatorDetail />}
            />
          </Routes>
        ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
`;

export default App;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { adminApp } from "../../realm/store";
import axios from "axios";
import * as Realm from "realm-web";
import { PREFIX } from "../../constants/labels";

type User = {
  id: string | undefined;
  email: string | undefined;
  role: string | undefined;
  name: string | undefined;
};

export type Credentials = {
  email: string;
  password: string;
};

type State = {
  user: User | null;
  isLoading: boolean;
  isCheckingSession: boolean;
};

const initialState = {
  user: null,
  isLoading: false,
  isCheckingSession: false,
};

type FetchUserPayload = {
  credential: Credentials;
  errorHandler: () => void;
};

export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async ({ credential, errorHandler }: FetchUserPayload) => {
    try {
      const checkEmailValid = await axios.get(
        `${PREFIX}/api/admin/check-email?email=${credential.email}`
      );
      if (checkEmailValid.data.isAvailable) {
        const credentials = Realm.Credentials.emailPassword(
          credential.email,
          credential.password
        );
        await adminApp.logIn(credentials);
        await axios.post(`${PREFIX}/api/admin/logIn`, {
          adminId: adminApp.currentUser?.id,
        });
        return checkEmailValid;
      } else {
        errorHandler && errorHandler();
        throw new Error("Email is not valid");
      }
    } catch (err) {
      errorHandler && errorHandler();
      console.error("Failed to log in", err);
    }
  }
);

export const logOut = createAsyncThunk("user/logOut", async () => {
  try {
    await adminApp.currentUser?.logOut();
    await axios.post(`${PREFIX}/api/admin/logOut`);
  } catch (err) {
    console.error("Failed to log out", err);
  }
});

export const checkSession = createAsyncThunk("user/checkSession", async () => {
  try {
    const user = await adminApp.currentUser;
    if (user) {
      await axios.post(`${PREFIX}/api/admin/logIn`, {
        adminId: adminApp.currentUser?.id,
      });
      const checkEmailValid = await axios.get(
        `${PREFIX}/api/admin/check-email?email=${adminApp.currentUser?.profile.email}`
      );
      return checkEmailValid;
    }
    return user;
  } catch (err) {
    console.error("Failed to log in", err);
  }
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUser.pending.toString()]: (state: State) => {
      state.isLoading = true;
    },
    [fetchUser.fulfilled.toString()]: (state: State, action) => {
      state.isLoading = false;
      state.user = {
        id: adminApp.currentUser?.id,
        email: adminApp.currentUser?.profile.email,
        role: action?.payload?.data.role,
        name: action?.payload?.data.name,
      };
    },
    [fetchUser.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      console.log("Error", action.payload);
    },
    [checkSession.pending.toString()]: (state: State) => {
      state.isCheckingSession = true;
    },
    [checkSession.fulfilled.toString()]: (state: State, action) => {
      state.isCheckingSession = false;
      const user = action.payload;
      if (user) {
        state.user = {
          id: adminApp.currentUser?.id,
          email: adminApp.currentUser?.profile.email,
          role: action?.payload?.data.role,
          name: action?.payload?.data.name,
        };
      }
    },
    [checkSession.rejected.toString()]: (state, action) => {
      state.isCheckingSession = false;
      console.log("Error", action.payload);
    },
    [logOut.pending.toString()]: (state: State) => {
      state.isCheckingSession = true;
    },
    [logOut.fulfilled.toString()]: (state: State, action) => {
      state.isCheckingSession = false;
      state.user = null;
    },
    [logOut.rejected.toString()]: (state, action) => {
      state.isCheckingSession = false;
      console.log("Error", action.payload);
    },
  },
});

export default userSlice.reducer;

import styled from "@emotion/styled";
import React, { FC } from "react";

interface Props {
  name: string;
  role: string;
  email: string;
}

const UserInfo: FC<Props> = ({ name = "", role = "", email = "" }: Props) => {
  return (
    <Container>
      <Name>{name}</Name>
      <RoleEmail>{`${role} | ${email}`}</RoleEmail>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Name = styled.div`
  display: flex;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #2e1a46;
`;

const RoleEmail = styled.div`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;

export default UserInfo;

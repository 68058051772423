import React, { FC } from "react";
import styled from "@emotion/styled";

interface Props {
  title: string;
  value: string;
  style?: React.CSSProperties;
}

const ShortInfo: FC<Props> = ({ title, value, style = {} }: Props) => {
  return (
    <Container style={style}>
      <Title>{title}</Title>
      <Value>{value}</Value>
    </Container>
  );
};

const Value = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  font-family: "Mulish";
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #81859a;
  font-family: "Mulish";
`;

const Container = styled.div`
  display: black;
  flex-direction: column;
`;

export default ShortInfo;
